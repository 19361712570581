import React, { useState, useEffect } from 'react';
import { getSinglePackage, } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable';
import { PencilLine, Trash2 } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';


const History = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [getServiceData, setServiceData] = useState([]);


  useEffect(() => {
    viewPackage()
  }, []);

  const viewPackage = async () => {
    const req = { id: location?.state?.row?.id };
    await dispatch(getSinglePackage(req)).unwrap()
      .then((response) => {
        if (response.status) {
          setServiceData(response.data[0].PackageHistories);
        } else {
          setServiceData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const DateFarmet = (newDate) => {
    const date = new Date(newDate);
    const formattedDate = format(date, 'eee, MMM d, yyyy h:mm a');
    return formattedDate;
  }

  const columns = [
    {
      name: "History",
      cell: (row) => (
        <>
          <div>
            <p>
              {row.history}
            </p>
            <p>
              {DateFarmet(row?.createdAt)}
            </p>
          </div>

        </>
      ),
      selector: (row) => row.history,
      sortable: true,
    },
    {
      name: "Discription",
      cell: (row) => row.description,
      selector: (row) => row.description,
      sortable: true,
    },

  ];

  return (
    <div>
      <section className="section dashboard">
        <div className="row">
          < div className='expandable-table'>
            <Datatable
              columns={columns}
              data={getServiceData && getServiceData || []}
              filter={false}
            />
          </div>
        </div>
      </section>

    </div>
  );
};

export default History;
