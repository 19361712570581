import axios from "axios";
import * as Config from "../../Utils/Config";


export async function LOGIN(data) {
    try {
        const res = await axios.post(`${Config.base_url}login`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}


 





