
const triggerEvents = [
    {
      value: "contract_email",
      label: "Contract - Email to Client with Signed Copy and Payment Details",
    },
    { value: "follow_up", label: "Follow-up Email" },
  ];

  
export const formArray = [
    {
      label: "Template name:",
      name: "templateName",
      type: "text",
    },
    {
      label: "Description:",
      name: "description",
      type: "textarea",
    },
    {
      label: "Trigger Event:",
      name: "triggerEvent",
      type: "select",
      options: triggerEvents,
    },
    {
      label: "Subject Line:",
      name: "subjectLine",
      type: "text",
    },
    {
      label: "BCC Email:",
      name: "bccEmail",
      type: "email",
    },
  ];

export const tab = [
    { value: "Client", label: "Client" },
    { value: "Practice", label: "Practice" },
    { value: "User Profile", label: "User Profile" },
  ];

export const ClientTags = [
    { value: "FIRST_NAME", label: "FIRST NAME" },
    { value: "FULL_NAME", label: "FULL NAME" },
    { value: "EMAIL", label: "EMAIL" },
    { value: "PHONE_NUMBER", label: "PHONE NUMBER" },
    { value: "JOINING_DATE", label: "JOINING DATE" },
    { value: "ADDRESS", label: "ADDRESS" },
    { value: "INVOICE_ADDRESS", label: "INVOICE ADDRESS" },
  ];