import React, { useState } from 'react'

const PricingDashboard = () => {
    const [activeTab, setActiveTab] = useState("current-month");

    const handleTabChange = (event) => {
      setActiveTab(event.target.value);
    };
  
  return (
    <>
        <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-12">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-4 col-md-4">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">Active packages</h5>
                          <div className="d-flex align-items-end justify-content-between pt-4">
                            <div className="">
                              <h6>171</h6>

                              
                            </div>
                            <div>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-4 col-md-4">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">Active services</h5>
                          <div className="d-flex align-items-end justify-content-between pt-4">
                            <div className="">
                              <h6>115</h6>

                             
                            </div>
                            <div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                    {/* Customers Card */}
                    <div className="col-xxl-4 col-md-4">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">Active charge basis</h5>
                          <div className="d-flex align-items-end justify-content-between pt-4">
                            <div className="">
                              <h6>115</h6>

                             
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Customers Card */}
               
                  </div>
                </div>
                {/* End Left side columns */}
               
              </div>
            </section>
    <div className="filter-select mb-3 select_tab">
      <select
        onChange={handleTabChange}
        value={activeTab}
        className="form-select"
      >
        <option value="current-month">Current Month</option>
        <option value="prev-month">Previous Month</option>
        <option value="current-quarter">Current Quarter</option>
        <option value="previous-quarter">Previous Quarter</option>
        <option value="current-calender-quarter">
          Current Calendar Year
        </option>
        <option value="prev-calender-year">Previous Calendar Year</option>
        <option value="custom-date">Custom Date</option>
      </select>
    </div>

    <div id="my_content">
      <div className="col-md-12">
        {activeTab === "current-month" && (
          <div>
            <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-7">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No. of packages sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>171</h6>

                              <span className="filter-text">
                                Last month: 169
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                1.2%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No of services sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>115</h6>

                              <span className="filter-text">
                                Last month: 106
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                3.9%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                
                  
                  </div>
                </div>
                {/* End Left side columns */}
                
              </div>
            </section>
          </div>
        )}
        {activeTab === "prev-month" && (
          <div>
            <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-7">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No. of packages sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>171</h6>

                              <span className="filter-text">
                                Last month: 169
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                1.2%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No of services sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>115</h6>

                              <span className="filter-text">
                                Last month: 106
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                3.9%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                
                  
                  </div>
                </div>
                {/* End Left side columns */}
                
              </div>
            </section>
          </div>
        )}
        {activeTab === "current-quarter" && (
          <div>
            <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-7">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No. of packages sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>171</h6>

                              <span className="filter-text">
                                Last month: 169
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                1.2%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No of services sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>115</h6>

                              <span className="filter-text">
                                Last month: 106
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                3.9%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                
                  
                  </div>
                </div>
                {/* End Left side columns */}
                
              </div>
            </section>
          </div>
        )}
        {activeTab === "previous-quarter" && (
          <div>
            <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-7">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No. of packages sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>171</h6>

                              <span className="filter-text">
                                Last month: 169
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                1.2%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No of services sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>115</h6>

                              <span className="filter-text">
                                Last month: 106
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                3.9%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                
                  
                  </div>
                </div>
                {/* End Left side columns */}
                
              </div>
            </section>
          </div>
        )}
        {activeTab === "current-calender-quarter" && (
          <div>
            <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-7">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No. of packages sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>171</h6>

                              <span className="filter-text">
                                Last month: 169
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                1.2%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No of services sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>115</h6>

                              <span className="filter-text">
                                Last month: 106
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                3.9%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                
                  
                  </div>
                </div>
                {/* End Left side columns */}
                
              </div>
            </section>
          </div>
        )}
       
        {activeTab === "prev-calender-year" && (
          <div>
            <section className="section dashboard">
              <div className="row">
                {/* Left side columns */}
                <div className="col-lg-7">
                  <div className="row">
                    {/* Sales Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No. of packages sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>171</h6>

                              <span className="filter-text">
                                Last month: 169
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                1.2%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Sales Card */}
                    {/* Revenue Card */}
                    <div className="col-xxl-6 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">No of services sold</h5>
                          <div className="d-flex align-items-end justify-content-between ">
                            <div className="">
                              <h6>115</h6>

                              <span className="filter-text">
                                Last month: 106
                              </span>
                            </div>
                            <div>
                              <div className=" small  ">
                                <i class="ri-arrow-up-circle-fill text-success fs-5 me-1"></i>
                                3.9%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Revenue Card */}
                
                  
                  </div>
                </div>
                {/* End Left side columns */}
                
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  </>
  )
}

export default PricingDashboard