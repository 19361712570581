import React, { useState, useEffect } from 'react';
import { viewPackage, } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable';
import { useNavigate } from 'react-router-dom';
import { PenLine, Trash2 } from 'lucide-react';


const Service = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [getPackageData, setPackageData] = useState([]);

  useEffect(() => {
    getAllPackage();;
  }, []);

  const getAllPackage = async () => {
    await dispatch(viewPackage()).unwrap()
      .then((response) => {
        if (response.status) {
          setPackageData(response.data);
        } else {
          setPackageData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };


  const columns = [
    {
      name: "Service Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status === 1 ? "Active" : "Inactive",
      sortable: true,
    },
    {
      name: 'applicableTo',
      selector: (row) => row.applicableTo,
      sortable: true

    },
    {
      name: "total Cost of Service",
      selector: (row) => row.cost_service_price,
      sortable: true,
    },
    {
      name: "Default Price",
      selector: (row) => row.default_price,
      sortable: true,
    },
    {
      name: "Threshold Price",
      selector: (row) => row.threshold_price,
      sortable: true,
    },
    {
      name: "Final Package Price",
      selector: (row) => row.final_package_price,
      sortable: true,
    },




    {
      name: "Action",
      selector: (row) => <>
        <PenLine
          onClick={() =>
            navigate('/admin/pricing/view-package', { state: { row: row }, })
          }
        />
        <Trash2 />
      </>,
      sortable: true,
    },
  ];

  return (
    <div>

      <section className="section dashboard">
        <div className="row">
          <div className='row'>
            <h5 className='col-lg-11'>
              <span className="ay-name" id="Day_Name">
                All Packages
              </span>
            </h5>
            <div className='col-lg-1'>
              <button className="btn btn-pink rounded ms-2 d-flex" onClick={() => window.history.back()}>
                <i className="ri-arrow-left-s-line me-2"></i> Back
              </button>
            </div>

          </div>
          < div className='expandable-table'>
            <Datatable
              columns={columns}
              data={getPackageData}
              filter={false}
            />
          </div>
        </div>
      </section>

    </div>
  );
};

export default Service;
