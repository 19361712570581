import React, { useState, useRef } from "react";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { formArray, tab, ClientTags } from "./EmailTempletInputs";

const EmailTemplateForm = () => {
  const [selectedTab, setSelectedTab] = useState("Client");
  const [cursorPosition, setCursorPosition] = useState(null);

  const [formData, setFormData] = useState({
    templateName: "",
    description: "",
    triggerEvent: "",
    subjectLine: "",
    bccEmail: "",
    replyTo: "",
    body: "",
    attachments: [],
  });



  const replyToOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (content) => {
    setFormData({ ...formData, body: content });
  };


  const handleTagClick = (tag) => {
    const editorContent = formData.body;
    const updatedContent =
      editorContent.slice(0, cursorPosition) + `{{${tag}}}` + editorContent.slice(cursorPosition);
    setFormData({ ...formData, body: updatedContent });
  };

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      attachments: [...formData.attachments, ...files],
    });
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = formData.attachments.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, attachments: updatedAttachments });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted: ", formData);
  };

  const handleCursorPosition = (e) => {
    
    const selection = window.getSelection();
    console.log(selection);
    const position = selection.anchorOffset;
    setCursorPosition(position);
  };

  

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/template/all-email-template">
              <i className="bx bx-arrow-back text-pink pe-1"></i>
            </Link>
            Create Email Template
          </h4>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body tempaltes">
              <div className="email-template-form container ">
                {formArray.map((item, index) => (
                  <div className="form-group mb-3" key={index}>
                    <div className="row">
                      <label className="col-sm-3 col-form-label">
                        {item.label}
                      </label>
                      <div className="col-sm-9">
                        {item.type === "text" && (
                          <input
                            type="text"
                            name={item.name}
                            value={formData[item.name]}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        )}
                        {item.type === "email" && (
                          <div className="row">
                            <div className="col-sm-10">
                              <input
                                type="email"
                                name={item.name}
                                value={formData[item.name]}
                                onChange={handleInputChange}
                                className="form-control"
                              />
                            </div>
                            <div className="col-sm-1">
                              <span>
                                <i className="bx bx-info-circle fs-4 mt-1"></i>
                              </span>
                            </div>
                          </div>
                        )}
                        {item.type === "select" && (
                          <Select
                            options={item.options}
                            onChange={(selectedOption) =>
                              setFormData({
                                ...formData,
                                [item.name]: selectedOption.value,
                              })
                            }
                            className="basic-single"
                          />
                        )}
                        {item.type === "textarea" && (
                          <textarea
                            name={item.name}
                            value={formData[item.name]}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="form-group mb-3">
                  <div className="row ">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-3 col-form-label"
                    >
                      Reply to:
                    </label>
                    <div className="col-sm-6">
                      <Select
                        options={replyToOptions}
                        onChange={(selectedOption) =>
                          setFormData({
                            ...formData,
                            replyTo: selectedOption.value,
                          })
                        }
                        className="basic-single"
                      />
                    </div>
                    <div className="col-sm-1 ps-0">
                      <span>
                        <i className="bx bx-info-circle fs-4 mt-1"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Body:</label>
                    <div className="col-sm-9">
                      <JoditEditor
                        value={formData.body}
                        onChange={handleEditorChange}
                        onClick={handleCursorPosition}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="row">
                    <div className="col-sm-3"></div>

                    <div className="col-sm-9">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <i className="bi bi-paperclip me-2"></i>
                        Add Attachment
                      </button>
                      <input
                        id="fileInput"
                        type="file"
                        multiple
                        onChange={handleAttachmentChange}
                        className="d-none"
                      />
                      <div className="attachment-list mt-3">
                        {formData.attachments.map((file, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-2"
                          >
                            <i className="bi bi-file-earmark"></i>
                            <span>{file.name}</span>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger d-flex align-items-center"
                              onClick={() => handleRemoveAttachment(index)}
                            >
                              <i className="bi bi-x"></i>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions d-flex justify-content-end gap-2">
                  <button type="submit" className="btn btn-pink rounded " onClick={handleSubmit}>
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary rounded "
                    onClick={() => console.log("Form Cancelled")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className="tag-provision  card"
            style={{ minWidth: "250px" }}
          >
            <div className="card-body pt-3">
              <h5 className="text-gray">
                Tag Provision <i className="bx bx-info-circle text-pink"></i>
              </h5>
              <ul className="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified" role="tablist">
                {tab.map((item, index) => (
                  <li className="nav-item flex-fill" role="presentation">
                    <button
                      className={"nav-link w-100 " + (selectedTab === item.value ? "active" : "")}
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bordered-justified-home"
                      type="button"
                      role="tab"
                      aria-controls="Client"
                      aria-selected="true"
                      onClick={() => setSelectedTab(item.value)}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab-content p-3" id="borderedTabJustifiedContent">
                <div
                  className="tab-pane fade show active"
                  id="bordered-justified-home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="">
                    {
                      selectedTab === "Client" && ClientTags.map((item, index) => (
                        <button
                          type="button"
                          className="tag-btn"
                          onClick={() => handleTagClick(item.value)}
                          key={index}
                        >
                          <i className="bi bi-plus-lg"></i>
                          {item.label}
                        </button>
                      ))
                    }
                  </div>
                </div>
                <div
                  className={"tab-pane" + (selectedTab === "Practice" ? " show active" : "")}
                  id="bordered-justified-profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  Nesciunt totam et. Consequuntur magnam aliquid eos nulla dolor
                  iure eos quia. Accusantium distinctio omnis et atque fugiat.
                  Itaque doloremque aliquid sint quasi quia distinctio similique.
                  Voluptate nihil recusandae mollitia dolores. Ut laboriosam
                  voluptatum dicta.
                </div>
                <div
                  className={"tab-pane" + (selectedTab === "User Profile" ? " show active" : "")}
                  id="bordered-justified-contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  Saepe animi et soluta ad odit soluta sunt. Nihil quos omnis
                  animi debitis cumque. Accusantium quibusdam perspiciatis qui qui
                  omnis magnam. Officiis accusamus impedit molestias nostrum
                  veniam. Qui amet ipsum iure. Dignissimos fuga tempore dolor.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailTemplateForm;
