import React from "react";

const NewQuote = () => {
  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title">
            New Quote{" "}
            <span>
              {" "}
              <i class="bi bi-chevron-right ms-auto"></i>
            </span>
          </h4>
        </div>
      </div>
      <div className="card pt-4">
        <div className="card-body">
          <form className=" needs-validation" noValidate="">
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label  "
                  >
                    Package type:
                  </label>
                  <div className="col-sm-8 ps-0">
                    <select className="form-select">
                      <option>Select an action</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                      <option value="3">Option 3</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <table className="table table-custom">
                  <thead className="">
                    <tr>
                      <th>Service</th>
                      <th>Type</th>
                      <th>Value</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="search-form d-flex align-items-center">
                          <input
                            type="text"
                            name="query"
                            className="form-control"
                            placeholder="Search for services"
                            title="Enter search keyword"
                          />

                         <i className='bx bx-search'></i>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Discount:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Premium:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Yealy Fee:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Payment Fee:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Net @20% VAT:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Net @ No VAT:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          VAT:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Total Including VAT (Yearly):
                        </label>
                        <div className="col-sm-5 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row ">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Payment Type:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <select className="form-select">
                            <option>Select an action</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-7 col-form-label  text-end "
                        >
                          Payment Frequency:
                        </label>
                        <div className="col-sm-5 ps-0">
                          <select className="form-select">
                            <option>Select an action</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>

            <div className=" col-auto text-end">
              <button className="btn btn-outline-secondary rounded">
                Cancel
              </button>
              <button className="btn btn-pink rounded ms-2">Next</button>
            </div>
          </form>
          {/* End Custom Styled Validation */}
        </div>
      </div>
    </div>
  );
};

export default NewQuote;
