import React, { useEffect,useState } from 'react';
import { Link } from "react-router-dom";
import SimpleDataTable from '../../ExtraComponents/SimpleDatatable';  // Import the child component

const ClientDashboard = () => {

   // State for columns and data
   const [columns] = useState([
    { name: 'Name', key: 'name' },
    { name: 'Email', key: 'email' }, // Updated field: Email
    { name: 'Type', key: 'type' },   // Updated field: Type
    { name: 'Status', key: 'status' }, // Updated field: Status
    { name: 'Action', key: 'action' } // Action column remains
  ]);

  const [data] = useState([
    { name: 'Dale Rush', email: 'dale@example.com', type: 'Admin', status: 'Active', action: 'Edit' },
    { name: 'Shellie Murphy', email: 'shellie@example.com', type: 'User', status: 'Inactive', action: 'Edit' },
    { name: 'Porter Nicholson', email: 'porter@example.com', type: 'Admin', status: 'Active', action: 'Edit' },
    { name: 'Jane Doe', email: 'jane@example.com', type: 'User', status: 'Active', action: 'Edit' },
    { name: 'John Smith', email: 'john@example.com', type: 'User', status: 'Inactive', action: 'Edit' },
    { name: 'Alex Johnson', email: 'alex@example.com', type: 'Admin', status: 'Active', action: 'Edit' },
    { name: 'Emily Davis', email: 'emily@example.com', type: 'User', status: 'Active', action: 'Edit' },
    { name: 'Michael Brown', email: 'michael@example.com', type: 'Admin', status: 'Inactive', action: 'Edit' },
    // Add more rows as needed
  ]);

  // Only show the first 10 rows
  const limitedData = data.slice(0, 5);

  // Handler for the Edit button
  const handleEdit = (clientName) => {
    console.log(`Edit client: ${clientName}`);
  };

  // Handler for the Delete button
  const handleDelete = (clientName) => {
    console.log(`Delete client: ${clientName}`);
  };

  const dataWithActions = limitedData.map(client => ({
    ...client,
    action: (
      <div>
       
        <button className="btn fs-4 py-0" onClick={() => handleEdit(client.name)}>
          <i className='ri-edit-line'></i>
        </button>
        <button className="btn fs-4 py-0" onClick={() => handleDelete(client.name)}>
          <i className='bx bx-trash'></i>
        </button>
      </div>
    ),
  }));

  


  return (
    <div>  
      <>
        <div className="row mb-3">
          <div className="col">
            <h4 className="page-title">Clients</h4>
          </div>
          <div className="col-auto align-self-center">
            <Link to="/admin/client/create-clients" className="btn btn-pink" id="Dash_Date">
              <span className="ay-name" id="Day_Name">
                Add New Client
              </span>
            </Link>
          </div>
        </div>

        <div className="filter-select mb-3">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
          >
            <option selected="">Current Month</option>
            <option value={1}>Last Month</option>
            <option value={2}>Two</option>
            <option value={3}>Three</option>
          </select>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">New Clients</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>171</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
        
               
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Clients Left</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>115</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Total Clients</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>115</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </section>
        <section className="section dashboard">
        <div className="row">
        <div className="col">
            <span className="text-pink font-16">Recent</span>
          </div>
          <div className="col-auto align-self-center">
            <Link to="/admin/client/all-client" >
            <span className="text-pink font-16">See All</span>
            </Link>
          </div>
        
        <SimpleDataTable columns={columns} data={dataWithActions} perPage={5} perPageSelect={false} />
       
        </div>
      </section>
      </>
    </div>
  );
}

export default ClientDashboard;
