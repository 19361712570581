import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import ReusableForm from "../../../ExtraComponents/ReusableForm1";
import { useNavigate } from "react-router-dom";
import { getBasisType, getChargeBasisbyId, updateChargeBasis } from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import sweatalert from "sweetalert2";
import { useLocation } from "react-router-dom";

const CreateChargebasis = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");
    const [getBasisTypeData, setGetBasisTypeData] = useState([]);
    const [brandRangeArr, setBrandRangeArr] = useState([{ id: "", name: "", value: "" }]);
    const [frequencyArr, setFrequencyArr] = useState([{ id: "", name: "", value: "" }]);
    const [unitName, setUnitName] = useState({ id: "", name: "", value: "" });
    const [yesNoValue, setYesnoValue] = useState({ id: "", name: "", value: "" });
    const [errors, setErrors] = useState({ perUnit: {}, brandRange: {}, frequency: {}, yesNo: {}, });
    const [chargeBasisEditData, setChargeBasisEditData] = useState([]);

    useEffect(() => {
        GetBasisType();
        getEditData();
    }, []);

    useEffect(() => {
        if (chargeBasisEditData?.[0]) {
            formik.setValues({
                name: chargeBasisEditData?.[0]?.name,
                basistypes_id: chargeBasisEditData?.[0]?.basistypes_id,
                tags: chargeBasisEditData?.[0]?.tags,
                status: chargeBasisEditData?.[0]?.status == 1 ? 1 : 0,
                input_required: chargeBasisEditData?.[0]?.input_required == 1 ? 1 : 0,
            });
            if (chargeBasisEditData?.[0]?.basistypes_id == 1 || chargeBasisEditData?.[0]?.basistypes_id == 2) {
                setUnitName({ id: chargeBasisEditData?.[0]?.ChargeTypes?.[0]?.id, name: chargeBasisEditData?.[0]?.ChargeTypes?.[0]?.name, value: chargeBasisEditData?.[0]?.ChargeTypes?.[0]?.value });
            }
            if (chargeBasisEditData?.[0]?.basistypes_id == 3) {
                setBrandRangeArr(chargeBasisEditData?.[0]?.ChargeTypes?.map((item) => ({
                    name: item.name, value: item.value, id: item.id
                })));
            }
            if (chargeBasisEditData?.[0]?.basistypes_id == 4) {
                setFrequencyArr(chargeBasisEditData?.[0]?.ChargeTypes?.map((item) => ({
                    name: item.name, value: item.value, id: item.id
                }
                )));
            }
            if (chargeBasisEditData?.[0]?.basistypes_id == 5) {
                setYesnoValue({ id: chargeBasisEditData?.[0]?.ChargeTypes?.[0]?.id, name: chargeBasisEditData?.[0]?.ChargeTypes?.[0]?.name, value: chargeBasisEditData?.[0]?.ChargeTypes?.[0]?.value });
            }
        }
    }, [chargeBasisEditData]);

    const getEditData = async () => {
        const req = { token: token, id: location.state.data.id };
        try {
            const res = await dispatch(getChargeBasisbyId(req)).unwrap();
            if (res.status) {
                setChargeBasisEditData(res.data);
            } else {
                setChargeBasisEditData([]);
            }
        } catch (err) {
            console.log("Error in getting charge basis data", err);
        }
    };


    const GetBasisType = async () => {
        const req = { token: token };
        try {
            const res = await dispatch(getBasisType(req)).unwrap();
            if (res.status) {
                setGetBasisTypeData(res.data);
            } else {
                setGetBasisTypeData([]);
            }
        } catch (err) {
            console.log("Error in getting Basis Type", err);
        }
    };

    const handleFrequencyChange = (index, type, value) => {
        const currentItem = frequencyArr[index];
        const name = type === "name" ? value : currentItem?.name || "";
        const val = type === "value" ? value : currentItem?.value || "";

        const updatedArray = frequencyArr.map((item, idx) =>
            idx === index ? { ...item, name: name, value: val } : item
        );
        setFrequencyArr(updatedArray);
    };

    const handleRangeChange = (index, type, value) => {
        const currentItem = brandRangeArr[index];
        const minValue = type === "minValue" ? value : currentItem?.name?.split('-')[0] || "";
        const maxValue = type === "maxValue" ? value : currentItem?.name?.split('-')[1] || "";
        const val = type === "value" ? value : currentItem?.value || "";
        const newRange = `${minValue}-${maxValue}`;
        const updatedArray = brandRangeArr.map((item, idx) =>
            idx === index ? { ...item, name: newRange, value: val} : item
        );
        setBrandRangeArr(updatedArray);
    };

    const removeBasisRange = (index) => {
        const newBrandRange = brandRangeArr.filter((_, idx) => idx !== index);
        setBrandRangeArr(newBrandRange);
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (updatedErrors.brandRange && typeof updatedErrors.brandRange === "object") {
                delete updatedErrors.brandRange[index];
            }
            return updatedErrors;
        });
    };

    const removeFrequency = (index) => {
        setFrequencyArr(frequencyArr.filter((_, idx) => idx !== index));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (updatedErrors.frequency && typeof updatedErrors.frequency === "object") {
                delete updatedErrors.frequency[index];
            }
            return updatedErrors;
        });

    };


    const formik = useFormik({
        initialValues: {
            name: "",
            basistypes_id: 1,
            tags: "",
            status: 0,
            input_required: 0,
        },
        validate: (values) => {
            let errors = {};
            if (!values.name) {
                errors.name = "Name is required";
            }
            if (!values.basistypes_id) {
                errors.basistypes_id = "Basis Type is required";
            }
            if (!values.tags) {
                errors.tags = "Tags are required";
            }
            return errors;
        },
        onSubmit: async (values) => {
            let formIsValid = true;
            const newErrors = {
                perUnit: {},
                brandRange: {},
                frequency: {},
                yesNo: {},
            };
            if (formik.values.basistypes_id == 1 || formik.values.basistypes_id == 2) {
                if (!unitName.name) {
                    newErrors.perUnit.unitName = "Please enter the name of unit";
                    formIsValid = false;
                }

                if (!unitName.value) {
                    newErrors.perUnit.unitValue = "Please enter the price per unit";
                    formIsValid = false;
                }
            }
            if (formik.values.basistypes_id == 3) {
                brandRangeArr.forEach((item, index) => {
                    if (item.name.split('-')[0] == "") {
                        newErrors.brandRange = newErrors.brandRange || [];
                        newErrors.brandRange[index] = {
                            ...newErrors.brandRange[index],
                            minValue: "Please enter the minValue",
                        };
                        formIsValid = false;
                    }
                    if (item.name.split('-')[1] == "") {
                        newErrors.brandRange = newErrors.brandRange || [];
                        newErrors.brandRange[index] = {
                            ...newErrors.brandRange[index],
                            maxValue: "Please enter the maxValue",
                        };
                        formIsValid = false;
                    }

                    if (!item.value) {
                        newErrors.brandRange = newErrors.brandRange || [];
                        newErrors.brandRange[index] = {
                            ...newErrors.brandRange[index],
                            value: "Please enter the value",
                        };
                        formIsValid = false;
                    }
                });
            }
            if (formik.values.basistypes_id == 4) {
                frequencyArr.forEach((item, index) => {
                    if (!item.name) {
                        newErrors.frequency = newErrors.frequency || [];
                        newErrors.frequency[index] = {
                            ...newErrors.frequency[index],
                            name: "Please enter the name",
                        };
                        formIsValid = false;
                    }
                    if (!item.value) {
                        newErrors.frequency = newErrors.frequency || [];
                        newErrors.frequency[index] = {
                            ...newErrors.frequency[index],
                            value: "Please enter the value",
                        };
                        formIsValid = false;
                    }
                });
            }
            if (formik.values.basistypes_id == 5) {
                if (!yesNoValue.name) {
                    newErrors.yesNo.yesNoName = "Please enter the name";
                    formIsValid = false;

                }
                if (!yesNoValue.value) {
                    newErrors.yesNo.yesNoValue = "Please enter the value";
                    formIsValid = false;
                }
            }
            if (!formIsValid) {
                setErrors(newErrors);
                return;
            }

            const req = {
                id: location?.state?.data?.id,
                name: values.name,
                basistypes_id: Number(values.basistypes_id),
                tags: values.tags,
                status: values.status == 1 ? '1' : '0',
                input_required: values.input_required == 1 ? '1' : '0',
                basis_type: formik.values.basistypes_id == 1 || formik.values.basistypes_id == 2 ? [unitName] :
                    formik.values.basistypes_id == 3 ? brandRangeArr : formik.values.basistypes_id == 4 ? frequencyArr : [yesNoValue],
            };

            console.log("req", req);
            await dispatch(updateChargeBasis(req)).unwrap()
                .then((res) => {
                    console.log("res", res);
                    if (res.status) {
                        sweatalert.fire({
                            title: res.msg,
                            icon: "success",
                            timer: 3000,
                        })
                        navigate("/admin/pricing/all-charge-basis");

                    } else {
                        sweatalert.fire({
                            title: res.msg,
                            icon: "error",
                            timer: 3000,
                        })
                    }
                })
                .catch((err) => {
                    console.log("Error in creating charge basis", err);
                });
        },
    });

    const fields = [
        {
            type: "text",
            name: "name",
            label: "Name of charge basis",
            label_size: 5,
            col_size: 6,
            disable: false,
            child_col_size: 7,
        },
        {
            type: "select1",
            name: "basistypes_id",
            label: "Basis Type",
            options: getBasisTypeData?.map((data) => ({ value: data.id, label: data.name })),
            label_size: 5,
            col_size: 6,
            disable: true,
            child_col_size: 7,
        },
        {
            type: "text",
            name: "tags",
            label: "Tags",
            label_size: 5,
            col_size: 6,
            disable: false,
            child_col_size: 7,
        },
        {
            type: "hidden",
            name: "hiding_tags",
            label: "Tags",
            label_size: 5,
            col_size: 6,
            disable: false,
            child_col_size: 7,
        },
        {
            type: "togglebtn",
            name: "status",
            label: "Status",
            label_size: 5,
            col_size: 6,
            disable: false,
            child_col_size: 7,
        },
        {
            type: "togglebtn",
            name: "input_required",
            label: "Input Required",
            label_size: 5,
            col_size: 6,
            disable: false,
            child_col_size: 7,
        },
    ];

    const handleChangeInputs = (index, type, name, e) => {

        let value = e.target.value;
    
        if (type == 1) {
          if (name == "unitValue" && isNaN(value)) return;
          validate(index, type, name, value);
          setUnitName((unitName) => ({ ...unitName, [name == "unitValue" ? 'value' : 'name']: value }));
        }
        if (type == 2) {
          if (name == "value" && !/^\d*\.?\d*$/.test(value)) {
            e.target.value = ""
            return
          } else {
    
          }
          validate(index, type, name, value);
          handleRangeChange(index, name, value);
    
        }
        if (type == 3) {
          if (name == "value" && !/^\d*\.?\d*$/.test(value)) {
            e.target.value = ""
            return
          } else {
    
          }
          validate(index, type, name, value);
          handleFrequencyChange(index, name, value);
    
        }
        if (type == 4) {
          if (!/^\d*\.?\d*$/.test(value)) {
            e.target.value = ""
            return
          }
          else {
    
          }
    
          validate(index, type, name, value);
          setYesnoValue((yesNoValue) => ({ ...yesNoValue, [name == "yesNoValue" ? 'value' : 'name']: value }));
        }
      }

    const validate = (index, type, name, value) => {
        const newErrors = { ...errors };
        if (type == 1) {
            if (!value) {
                newErrors.perUnit[name] = `Please enter the ${name == 'unitValue' ? 'value' : 'name'}`;
            } else {
                delete newErrors.perUnit[name];
            }
            setErrors(newErrors);
        }
        else if (type === 2) {

            if (!value) {
                newErrors.brandRange = newErrors.brandRange || [];
                newErrors.brandRange[index] = {
                    ...newErrors.brandRange[index],
                    [name]: `Please enter the ${name}`,
                };
            } else {
                if (newErrors.brandRange?.[index]) {
                    delete newErrors.brandRange[index][name];
                    if (Object.keys(newErrors.brandRange[index]).length === 0) {
                        delete newErrors.brandRange[index];
                    }
                }
            }

            setErrors(newErrors);
        }
        else if (type === 3) {
            if (!value) {
                newErrors.frequency = newErrors.frequency || [];
                newErrors.frequency[index] = {
                    ...newErrors.frequency[index],
                    [name]: `Please enter the ${name}`,
                };
            } else {
                if (newErrors.frequency?.[index]) {
                    delete newErrors.frequency[index][name];
                    if (Object.keys(newErrors.frequency[index]).length === 0) {
                        delete newErrors.frequency[index];
                    }
                }
            }
            setErrors(newErrors);
        }
        else if (type === 4) {
            if (!value) {
                newErrors.yesNo[name] = `Please enter the ${name == 'yesNoValue' ? 'value' : 'name'}`;
            } else {
                delete newErrors.yesNo[name];
            }
            setErrors(newErrors);
        }
    }

    const handleClose = () => {
        navigate("/admin/pricing/all-charge-basis");
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col">
                    <h4 className="page-title">
                        <Link to="/admin/pricing/all-charge-basis">
                            <i className="bx bx-arrow-back text-pink"></i>
                        </Link> Charge Basis
                    </h4>
                </div>
            </div>
            <div className="card form-card">
                <div className="card-header">
                    <h5 className="card-title">Edit Charge Basis</h5>
                </div>
                <div className="card-body">
                    <ReusableForm
                        fieldtype={fields.filter((field) => !field.showWhen || field.showWhen(formik.values))}
                        formik={formik}
                        btn_name="Save"
                        closeBtn={handleClose}
                        additional_field={
                            formik.values.basistypes_id == "2" || formik.values.basistypes_id == "1" ? (
                                <div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <label className="col-sm-3 col-form-label" style={{ marginRight: "35px" }}>
                                                Name of Unit:
                                            </label>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Name of Unit"
                                                    name="unitName"
                                                    onChange={(e) => handleChangeInputs(0, 1, "unitName", e)}
                                                    value={unitName.name}
                                                />
                                                {Object.keys(errors?.perUnit)?.length > 0 && (
                                                    <div className="text-danger">{errors?.perUnit?.unitName}</div>
                                                )}
                                            </div>
                                        </div >
                                        <div className="row mt-3">
                                            <label className="col-sm-3 col-form-label" style={{ marginRight: "35px" }}>
                                                Price per Unit:
                                            </label>
                                            <div className="col-4 d-flex">
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price per Unit"
                                                        name="unitValue"
                                                        onChange={(e) => handleChangeInputs(0, 1, "unitValue", e)}
                                                        value={unitName.value}
                                                    />
                                                    {Object.keys(errors?.perUnit)?.length > 0 && (
                                                        <div className="text-danger">{errors?.perUnit?.unitValue}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : formik.values.basistypes_id == "3" ? (
                                <div className="col-lg-8">
                                    <div className="row">
                                        <label className="col-lg-3 col-form-label" style={{ marginRight: "40px" }}>
                                            Set Defaults Bands:
                                        </label>
                                        <div className="col-lg-7">
                                            {brandRangeArr.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="d-flex mb-2">
                                                        {/* <select className="form-select w-25 border-right-0">
                                                            <option>NA</option>
                                                            <option>1</option>
                                                        </select> */}
                                                        <input
                                                            type="text"
                                                            className={`form-control border-right-0 w-75`}
                                                            placeholder="Min Value"
                                                            name={`minValue_${index}`}
                                                            value={item.name.split('-')[0]}
                                                            onChange={(e) => handleChangeInputs(index, 2, "minValue", e)}
                                                        />
                                                        <input
                                                            type="text"
                                                            className={`form-control w-75`}
                                                            placeholder="Max Value"
                                                            name={`maxValue_${index}`}
                                                            value={item.name.split('-')[1]}
                                                            onChange={(e) =>
                                                                handleChangeInputs(index, 2, "maxValue", e)
                                                            }
                                                        />
                                                        <div className="d-flex ms-2">
                                                            <span className="currency">
                                                                 <i className="bx bx-pound"></i>
                                                            </span>
                                                            <input
                                                                type="text"
                                                                name={`value_${index}`}
                                                                className={`form-control w-75`}
                                                                placeholder="Value"
                                                                value={item.value}
                                                                onChange={(e) => handleChangeInputs(index, 2, "value", e)}
                                                            />
                                                        </div>

                                                        { console.log("deleted check",chargeBasisEditData?.[0] )
                                                         }
                                                        {brandRangeArr.length == 1 || !location?.state?.data?.deleted ? "" : (

                                                            <button
                                                                className="btn bg-dark rounded-0 px-1 ms-1"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    removeBasisRange(index);
                                                                }}
                                                            >
                                                               
                                                            
                                                                <i className="bi bi-dash text-white"></i>
                                                            </button>)}
                                                    </div>
                                                    {errors?.brandRange && errors?.brandRange?.[index] && (
                                                        <div className="text-danger">
                                                            {errors?.brandRange?.[index]?.minValue || errors?.brandRange?.[index]?.maxValue || errors?.brandRange?.[index]?.value}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            { !location?.state?.data?.deleted ? "" :
                                            <button className="btn btn-pink rounded-0 mt-1" onClick={() => setBrandRangeArr([...brandRangeArr, { name: "", value: "", id: "" }])}>
                                                <i className="bi bi-plus-lg"></i>
                                            </button>
                                           }
                                        </div>
                                    </div>
                                </div>
                            ) : formik.values.basistypes_id == "4" ? (
                                <div className="row mb-4">
                                    {frequencyArr.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <label className="col-sm-4 col-form-label mb-3" style={{ marginRight: "35px" }}>
                                                            Frequency Name:
                                                        </label>
                                                        <div className="col-7">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name={`name_${index}`}
                                                                onChange={(e) =>
                                                                    handleChangeInputs(index, 3, "name", e)
                                                                }
                                                                value={item.name}
                                                            />
                                                            {errors.frequency && errors.frequency[index] && errors.frequency[index].name && (
                                                                <div className="text-danger">
                                                                    {errors.frequency[index].name}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <label className="col-sm-4 col-form-label" style={{ marginRight: "30px" }}>
                                                            Value:
                                                        </label>
                                                        <div className="col-7">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name={`value_${index}`}
                                                                onChange={(e) =>
                                                                    handleChangeInputs(index, 3, "value", e)
                                                                }
                                                                value={item.value}
                                                            />
                                                            {errors.frequency && errors.frequency[index] && errors.frequency[index].value && (
                                                                <div className="text-danger">
                                                                    {errors.frequency[index].value}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    {frequencyArr.length == 1 || !location?.state?.data?.deleted ? "" : (
                                                        <button
                                                            className="btn bg-dark rounded-0 p-1"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeFrequency(index);
                                                            }}
                                                        >
                                                            <i className="bi bi-dash text-white p-2"></i>
                                                        </button>)}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    { !location?.state?.data?.deleted ? "" :
                                    <div className="col-1 d-flex justify-content-end w-100">
                                        <button className="btn btn-pink rounded-0 mt-1" style={{ marginRight: "18px" }} onClick={() => setFrequencyArr([...frequencyArr, { name: "", value: "" , id: "" }])}>
                                            <i className="bi bi-plus-lg"></i>
                                        </button>
                                    </div>
                                    }
                                </div>
                            ) : formik.values.basistypes_id == "5" ? (
                                <div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <label className="col-sm-3 col-form-label" style={{ marginRight: "35px" }}>
                                                If Yes:
                                            </label>
                                            <div className="col-6">

                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={(e) => handleChangeInputs(0, 4, "yesNoName", e)}
                                                        value={yesNoValue.name}
                                                    />
                                                </div>
                                                {
                                                    Object.keys(errors?.yesNo)?.length > 0 && (
                                                        <div className="text-danger">{errors.yesNo.yesNoName}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-sm-3 col-form-label" style={{ marginRight: "35px" }}>
                                                If No:
                                            </label>
                                            <div className="col-6">
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={(e) => handleChangeInputs(0, 4, "yesNoValue", e)}
                                                        value={yesNoValue.value}
                                                    />
                                                </div>
                                                {
                                                    Object.keys(errors?.yesNo)?.length > 0 && (
                                                        <div className="text-danger">{errors.yesNo.yesNoValue}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : ""
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default CreateChargebasis;