import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";

const CreateContractTemplate = () => {
  // Define the available tags
  const availableTags = [
    { id: 1, label: "FIRST NAME" },
    { id: 2, label: "FULL NAME" },
    { id: 3, label: "EMAIL" },
    { id: 4, label: "PHONE NUMBER" },
    { id: 5, label: "JOINING DATE" },
    { id: 6, label: "ADDRESS" },
    { id: 7, label: "INVOICE ADDRESS" },
    // Add more tags as needed
  ];

  // State to track the added tags
  const [selectedTags, setSelectedTags] = useState([]);

  // Reference to the ReactQuill component
  const quillRef = useRef(null);

  // Function to handle adding a tag
  const handleTagClick = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
    // Insert the tag into the Quill editor
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();
    if (selection) {
      const cursorPosition = selection.index;
      quill.insertText(cursorPosition, `{{${tag}}}`);
      quill.setSelection(cursorPosition + tag.length + 4); // Move cursor after the inserted tag
    }
  };

  // Function to handle removing a tag
  const handleTagRemove = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  // Quill toolbar options
  const toolbarOptions = [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ];

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/clientdashboard">
              <i className="bx bx-arrow-back text-pink pe-1"></i>
            </Link>
            Create Contract Template
          </h4>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body tempaltes">
              <form className=" needs-validation" noValidate="">
                <div className="row mb-4">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Template name:
                      </label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" required />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Description:
                      </label>
                      <div className="col-sm-9">
                        <textarea className="form-control" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Trigger Event:
                      </label>
                      <div className="col-sm-9">
                        <select className="form-select" required>
                          <option>
                            Contract - Email to Client with Signed Copy and
                            Payment Details
                          </option>
                          <option>
                            Quote - Email Template when Quote is Sent{" "}
                          </option>
                          <option>
                            Contract - Email to Client for Signature
                          </option>
                          <option>
                            Contract - Email to Client with Signed Copy and
                            Payment Details{" "}
                          </option>
                          <option>
                            Contract - Email to Contract Owner when Contract is
                            Viewed
                          </option>
                          <option>
                            Quote - Email to Contract Owner when Quote is
                            Declined{" "}
                          </option>
                          <option>
                            Quote - Email to Contract Owner when Quote is
                            Accepted
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Subject line:
                      </label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" required />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        BCC Email:
                      </label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" required />
                      </div>
                      <div className="col-sm-1 ps-0">
                        <span>
                          <i className="bx bx-info-circle fs-4 mt-1"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row ">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Reply to:
                      </label>
                      <div className="col-sm-6">
                        <select className="form-select" required>
                          <option>1</option>
                          <option>1</option>
                        </select>
                      </div>
                      <div className="col-sm-1 ps-0">
                        <span>
                          <i className="bx bx-info-circle fs-4 mt-1"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <label className="col-sm-3 col-form-label">Body:</label>
                      <div className="col-sm-9">
                        <ReactQuill
                          ref={quillRef}
                          theme="snow"
                          modules={{ toolbar: toolbarOptions }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-9">
                    <div className="card mt-2">
                      <div className="card-header py-1 d-flex justify-content-between align-items-center">
                        <div className="card-title">Templates</div>
                        <div className="">
                          <i className="bi bi-plus text-pink fs-4"></i>
                        </div>
                      </div>
                      <div className="card-body pt-3">
                        <div className="tag-buttons">
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            FIRST NAME
                          </button>
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            FULL NAME
                          </button>
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            EMAIL
                          </button>
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            PHONE NUMBER
                          </button>
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            JOINING DATE
                          </button>
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            ADDRESS
                          </button>
                          <button className="tag-btn">
                            <i className="bi bi-plus-lg" />
                            INVOICE ADDRESS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-auto text-end">
                  <button className="btn btn-outline-secondary rounded">
                    Cancel
                  </button>
                  <button className="btn btn-outline-pink rounded ms-2">
                  Preview
                  </button>
                  <button className="btn btn-pink rounded ms-2">Submit</button>
                </div>
              </form>
            </div>
          </div>

          <div className="quill-editor-full"></div>
        </div>
        <div className="col-md-4">
          <div className="card pt-3 px-2">
            <h5 className="text-gray">
              Tag Provision <i class="bx bx-info-circle text-pink"></i>
            </h5>
            <ul
              class="nav nav-tabs nav-tabs-bordered d-flex"
              id="borderedTabJustified"
              role="tablist"
            >
              <li class="nav-item flex-fill" role="presentation">
                <button
                  class="nav-link w-100 active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bordered-justified-home"
                  type="button"
                  role="tab"
                  aria-controls="Client"
                  aria-selected="true"
                >
                  Client
                </button>
              </li>
              <li class="nav-item flex-fill" role="presentation">
                <button
                  class="nav-link w-100"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bordered-justified-profile"
                  type="button"
                  role="tab"
                  aria-controls="Practice"
                  aria-selected="false"
                >
                  Practice
                </button>
              </li>
              <li class="nav-item flex-fill" role="presentation">
                <button
                  class="nav-link w-100"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bordered-justified-contact"
                  type="button"
                  role="tab"
                  aria-controls="User-Profile"
                  aria-selected="false"
                >
                  User Profile
                </button>
              </li>
            </ul>
            <div class="tab-content p-3" id="borderedTabJustifiedContent">
              <div
                class="tab-pane fade show active"
                id="bordered-justified-home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {/* Tag buttons */}
                <div className="tag-buttons">
                  {availableTags.map((tag) => (
                    <button
                      key={tag.id}
                      onClick={() => handleTagClick(tag.label)}
                      className="tag-btn"
                    >
                      <i className="bi bi-plus-lg"></i>
                      {tag.label}
                    </button>
                  ))}
                </div>{" "}
              </div>
              <div
                class="tab-pane fade"
                id="bordered-justified-profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                Nesciunt totam et. Consequuntur magnam aliquid eos nulla dolor
                iure eos quia. Accusantium distinctio omnis et atque fugiat.
                Itaque doloremque aliquid sint quasi quia distinctio similique.
                Voluptate nihil recusandae mollitia dolores. Ut laboriosam
                voluptatum dicta.
              </div>
              <div
                class="tab-pane fade"
                id="bordered-justified-contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                Saepe animi et soluta ad odit soluta sunt. Nihil quos omnis
                animi debitis cumque. Accusantium quibusdam perspiciatis qui qui
                omnis magnam. Officiis accusamus impedit molestias nostrum
                veniam. Qui amet ipsum iure. Dignissimos fuga tempore dolor.
              </div>
            </div>

            {/* Selected tags display */}
            <div className="selected-tags">
              {selectedTags.map((tag, index) => (
                <span key={index} className="selected-tag">
                  {tag}
                  <button
                    onClick={() => handleTagRemove(tag)}
                    className="remove-btn"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateContractTemplate;
