import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleDataTable from "../../ExtraComponents/SimpleDatatable"; // Import the child component
import Datatable from '../../ExtraComponents/ReusableTable1';

const QuotesDashboard = () => {
  const columns = [
    {
      name: "Subject",
      selector: (row) => row.subject,
      width:'20%'
    },
    {
      name: "Client",
      selector: (row) => row.client,
    },
    {
      name: "Yearly Recurring Amount",
      selector: (row) => row.yearlyRecurringAmount,
    },
    {
      name: "Adhoc Amount",
      selector: (row) => row.adhocAmount,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Pricing ID/No",
      selector: (row) => row.pricingId,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      width:'20%',
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm-pink"
            onClick={() => handleEdit(row.client)}
          >
            <i className="ri-repeat-line pe-1"></i>
            Convert
          </button>
  
          <button
            className="btn fs-4 py-0"
            onClick={() => handleEdit(row.client)}
          >
            <i className="ri-edit-line"></i>
          </button>
          <button
            className="btn fs-4 py-0"
            onClick={() => handleDelete(row.client)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  
  const data = [
    {
      id: 1,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 1",
      yearlyRecurringAmount: "$1200",
      adhocAmount: "$200",
      date: "2024/01/01",
      pricingId: "PRIC-001",
      status: "Active",
    },
    {
      id: 2,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 2",
      yearlyRecurringAmount: "$1500",
      adhocAmount: "$300",
      date: "2023/12/15",
      pricingId: "PRIC-002",
      status: "Inactive",
    },
    {
      id: 3,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 3",
      yearlyRecurringAmount: "$2000",
      adhocAmount: "$500",
      date: "2024/03/10",
      pricingId: "PRIC-003",
      status: "Active",
    },
    {
      id: 4,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 4",
      yearlyRecurringAmount: "$2500",
      adhocAmount: "$100",
      date: "2024/05/20",
      pricingId: "PRIC-004",
      status: "Inactive",
    },
    {
      id: 5,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 5",
      yearlyRecurringAmount: "$1800",
      adhocAmount: "$400",
      date: "2024/02/28",
      pricingId: "PRIC-005",
      status: "Active",
    },
    {
      id: 6,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 6",
      yearlyRecurringAmount: "$2200",
      adhocAmount: "$150",
      date: "2024/06/01",
      pricingId: "PRIC-006",
      status: "Inactive",
    },
  ];
  
  
  const limitedData = data.slice(0, 5);
  // Handler for the Edit button
  const handleEdit = (clientName) => {
    console.log(`Edit client: ${clientName}`);
    // Add your edit logic here
  };

  // Handler for the Delete button
  const handleDelete = (clientName) => {
    console.log(`Delete client: ${clientName}`);
    // Add your delete logic here
  };

  // Render Action column with buttons
  const dataWithActions = limitedData.map((client) => ({
    ...client,
    action: (
      <div>
        <button
          className="btn btn-sm-pink  "
          onClick={() => handleEdit(client.name)}
        >
          <i className=" ri-repeat-line pe-1"></i>
          Convert
        </button>

        <button
          className="btn fs-4 py-0 "
          onClick={() => handleEdit(client.name)}
        >
          <i className=" ri-edit-line"></i>
        </button>
        <button
          className="btn fs-4 py-0"
          onClick={() => handleDelete(client.name)}
        >
          <i className="bx bx-trash"></i>
        </button>
      </div>
    ),
  }));

  const perPage = 5; // Show 10 rows per page
  const perPageSelect = false; // Dropdown options for per-page rows
  const searchable = false; // Enable search functionality
  const paging = false; // Enable paging functionality

  return (
    <div>
      <>
        <div className="row mb-3">
          <div className="col">
            <h4 className="page-title">Quotes</h4>
          </div>
          <div className="col-auto align-self-center">
            <Link
              to="/admin/quote/create-quote"
              className="btn btn-pink"
              id="Dash_Date"
            >
              <span className="ay-name" id="Day_Name">
                Add New Quote
              </span>
            </Link>
          </div>
        </div>

        <div className="filter-select mb-3">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
          >
            <option selected="">Current Month</option>
            <option value={1}>Last Month</option>
            <option value={2}>Two</option>
            <option value={3}>Three</option>
          </select>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Quotes Created</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>171</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Quotes Sent</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>115</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Quotes Reviewed</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>115</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Quotes Accepted</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>115</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Quotes Rejected</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>115</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section dashboard">
          <div className="row">
            <div className="col">
              <span className="text-pink font-16">Recent</span>
            </div>
            <div className="col-auto align-self-center">
              <Link to="/admin/quote/all-quotes">
                <span className="text-pink font-16">See All</span>
              </Link>
            </div>
           
            <Datatable
              columns={columns}
              data={data}
              filter={false}
             
            />
           
           
            {/* <SimpleDataTable
              columns={columns}
              data={dataWithActions}
              perPage={5}
              perPageSelect={false}
              searchable={searchable}
              paging={paging}
            /> */}
          </div>
        </section>
      </>
    </div>
  );
};

export default QuotesDashboard;
