// Add packege to show error message
export const EMPTY_PACKAGE_NAME_ERROR = "Please enter a valid package name";
export const EMPTY_APPLICABLE_TO_ERROR = "Please enter a valid applicable to";
export const EMPTY_TOTAL_COST_OF_SERVICES_ERROR = "Please enter a valid total cost of services";
export const EMPTY_DEFAULT_PACKAGE_PRICE_ERROR = "Please enter a valid default package price";
export const EMPTY_DEFAULT_DISCOUNT_ERROR = "Please enter a valid default discount";
export const EMPTY_THRESHOLD_PRICE_ERROR = "Please enter a valid threshold price";
export const EMPTY_MINIMUM_PRICE_ERROR = "Please enter a valid minimum price";
export const EMPTY_THRESHOLD_DISCOUNT_ERROR = "Please enter a valid threshold discount";
export const EMPTY_MAXIMUM_DISCOUNT_ERROR = "Please enter a valid maximum discount";


// create Service Error
export const EMPTY_NUMBER_OF_SERVICE_ERROR = "Number of services is required";
export const EMPTY_SERVICE_TYPE_ERROR = "Please Select a Service Type";

// Create Chage Basis Error
export const EMPTY_CHARGE_BASIS_NAME_ERROR = "Name is required";
export const EMPTY_CHARGE_BASIS_TYPE_ERROR = "Basis Type is required";
export const EMAPTY_TAGS_ERROR = "Tags are required"





