
export const base_url = `${window.location.origin}/backend/api/`;
// export const base_url = `http://localhost:5555/api/`;
// export const base_url = `https://pricingtools.tradestreet.in/backend/api/`;
// export const base_url = `http://192.168.0.44:5555/api/`;



// export const base_url = `http://185.209.75.6:2222/`;
//  export const base_url = `${window.location.origin}/backend/`


 

 

 









