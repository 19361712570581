import React, { useState, useEffect } from 'react';
import { getSinglePackage, getSingleService ,UpdatePackageServiceRules } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable';
import { PencilLine, Trash2 } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import ReusableModal from '../../../ExtraComponents/ReusableModal';
import { set } from 'date-fns';
import sweatalert from "sweetalert2";




const Service = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [getServiceData, setServiceData] = useState([]);
  const [serviceAllData, setServiceAllData] = useState([]);
  const [showDefultValueModal, setShowDefultValueModal] = useState(false);
  const [serviceRowData, setServiceRowData] = useState({});
  const [currentServiceRow, setCurrentServiceRow] = useState([]);
  const [requestChangeRuleId, setRequestChangeRuleId] = useState([]);


  const handleCloseDefultValueModal = () => {
    setShowDefultValueModal(false);
    setCurrentServiceRow([]);
  }

  useEffect(() => {
    viewPackage()
  }, []);

  const viewPackage = async () => {
    const req = { id: location?.state?.row?.id };
    await dispatch(getSinglePackage(req)).unwrap()
      .then((response) => {
        if (response.status) {
          setServiceData(response?.data[0]?.PackageServices);
          setServiceAllData(response?.data);
        } else {
          setServiceData([]);
          serviceAllData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const getServiceById = async (id) => {
    const req = { id: id };
    await dispatch(getSingleService(req)).unwrap()
      .then((response) => {
        if (response.status) {
          setServiceRowData(response?.data[0]);
        } else {
          setServiceRowData({});
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  }


  const columns = [
    {
      name: "Service Name",
      selector: (row) => row?.Service?.name,
      sortable: true,
    },
    {
      name: "Package Type",
      selector: (row) => row?.Service?.price_type == 1 ? "Fixed" : "Variable",
      sortable: true,
    },
    {
      name: "Default value",
      cell: (row) => row?.Service?.price_type == 1 ? "-" : row?.PackageServiceRules?.map((item) => {
        return item.ChargeType?.name + " : " + item.ChargeType?.value + ",  "
      }),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {row?.Service?.price_type == 2 ?
            <PencilLine
              onClick={() => {
                SetvalueModal(row);
                getServiceById(row?.Service?.id)
              }} /> : null}
          <Trash2 />
        </div>
      ),
    },
  ];


  const SetvalueModal = (row) => {
    setCurrentServiceRow(row);
    setShowDefultValueModal(true);
  }

  const handleChangeDefultValue = (e,index) => {
    const PackageServiceRules = currentServiceRow.PackageServiceRules[index];
    let change_id = e.target.value;
    let packageServiceRules_id= PackageServiceRules.id;
    let exist_id = PackageServiceRules.ChargeType.id;

    var pre_tag = {
      exist_id: exist_id,
      packageServiceRules_id: packageServiceRules_id,
      change_id: parseInt(change_id)
    };

    setRequestChangeRuleId(oldValues => {
      return oldValues.filter(item => item.exist_id !== exist_id)
    })
    setRequestChangeRuleId((oldArray) => [pre_tag, ...oldArray]);


  }


  const handleSumbit = async () => {
    if(requestChangeRuleId.length > 0){
      const req = {
        serviceId : serviceRowData?.id,
        packageId : location?.state?.row?.id,
        packageServiceRules : requestChangeRuleId
      }

      await dispatch(UpdatePackageServiceRules(req)).unwrap()
      .then((response) => {
        console.log("response", response);
        if (response.status) {
          sweatalert.fire({
            title : "Success",
            text : "Service Updated Successfully",
            icon : "success",
            timer : 1500,
            timerProgressBar : true
          }
          );
          viewPackage()
          setRequestChangeRuleId([]);
          setCurrentServiceRow([]);
          setShowDefultValueModal(false);
        } else {
          viewPackage()
          setRequestChangeRuleId([]);
          setShowDefultValueModal(false);
          setCurrentServiceRow([]);
        }
      })
      .catch((error) => {
        console.error("Error UpdatePackageServiceRules data:", error);
      });
    }else{
      setShowDefultValueModal(false);
      setCurrentServiceRow([]);
      viewPackage()

    }
   
    
  }
     


  return (
    <div>
      <section className="section dashboard">

        <div className="row">
          < div className='expandable-table'>
            <Datatable
              columns={columns}
              data={getServiceData || []}
              filter={false}
            />
          </div>
        </div>
      </section>
      <ReusableModal
        show={showDefultValueModal}
        onClose={handleCloseDefultValueModal}
        title={"Enter Defult Value"}
        body={<div>
          <div className="mb-3 row">
            {serviceRowData?.ServiceChargeBases?.map((data ,index) => (
              <div className="col-lg-12 row mb-2">
                <label className="col-lg-4 col-form-label" for="name">
                  {data?.ChargeBasis?.name}:
                </label>
                <div className="col-lg-8">
                  <select className="form-select"
                    defaultValue={currentServiceRow?.PackageServiceRules?.[index]?.ChargeType?.id}
                    onChange={(e) => handleChangeDefultValue(e,index)}
                   >
                    {data?.ServiceChargeBasisValues?.map((value) => (
                      <option value={value?.ChargeType?.id}>{value?.ChargeType?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>}
        footer={
          <>
            <button className="btn btn-outline-secondary" onClick={handleCloseDefultValueModal}>
              Cancel
            </button>
            <button className="btn btn-pink"
              onClick={handleSumbit}
            >
              Apply
            </button>
          </>
        }
      />

    </div>
  );
};

export default Service;
