import React from 'react';
import { Route, Routes, } from "react-router-dom";
import AdminHeader from '../Components/AdminHeader';
import AdminSidebar from '../Components/AdminSidebar';
import Dashboard from '../Layouts/Dashboard/Dashboard';
import CreateClient from '../Layouts/Clients/CreateClient';
import QuotesList from '../Layouts/Quotes/AllQuotes';
import QuotesDashboard from '../Layouts/Quotes/QuotesDashboard';
import ClientDashboard from '../Layouts/Clients/ClientDashboard';
import AllClients from '../Layouts/Clients/AllClients';
import CreateQuote from '../Layouts/Quotes/CreateQuote';
import EmailTemplate from '../Layouts/Templates/Email/CreateEmailTemplate';
import CreateChargebasis from '../Layouts/Pricing/Chargebasis/CreateChargebasis';
import AllChargebasis from '../Layouts/Pricing/Chargebasis/AllChargebasis';
import ContractTemplate from '../Layouts/Templates/Contract/CreateContractTemplate';
import ContractDashboard from '../Layouts/Contract/ContractDashboard';
import CreateContract from '../Layouts/Contract/CreateContract';
import AllContracts from '../Layouts/Contract/AllContracts';
import PricingDashboard from '../Layouts/Pricing/PricingDashboard';
import PackageDashboard from '../Layouts/Pricing/Package/PackageDashboard';
import CreatePackage from '../Layouts/Pricing/Package/CreatePackage';
import AllPackages from '../Layouts/Pricing/Package/AllPackages';
import AllServices from '../Layouts/Pricing/Services/AllServices';
import CreateServices from '../Layouts/Pricing/Services/CreateServices';
import EditChargebasis from '../Layouts/Pricing/Chargebasis/EditChargebasis';
import QuoteTemplate from '../Layouts/Templates/Quote/QuoteTemplate';
import CreateEmailTemplate from '../Layouts/Templates/Email/CreateEmailTemplate';
import DemoTemplate from '../Layouts/Templates/Email/DemoTemplate';
import CreateContractTemplate from '../Layouts/Templates/Contract/CreateContractTemplate';
import CreateQuoteTemplate from '../Layouts/Templates/Quote/CreateQuoteTemplate';
import AllEmailTemplate from '../Layouts/Templates/Email/AllEmailTemplate';
import ViewPackage from '../Layouts/Pricing/Package/ViewPackage';
import NewQuote from '../Layouts/Quotes/NewQuote';
import AddCompare from '../Layouts/Quotes/AddCompare'; 



const AdminRoute = () => {
    return (
        <div className="admin-container">
            <AdminHeader />
            <div className="admin-content">
                <AdminSidebar />
                <main id="main" className="main">
                    <div className="admin-main">
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/client/dashboard" element={<ClientDashboard />} />
                            <Route path="/client/create-clients" element={<CreateClient />} />
                            <Route path="/client/all-client" element={<AllClients />} />
                            <Route path="/quote/quotes-dashboard" element={<QuotesDashboard />} />
                            <Route path="/quote/create-quote" element={<CreateQuote />} />
                            <Route path="/quote/new-quote" element={<NewQuote />} />
                            <Route path="/quote/all-quotes" element={<QuotesList />} />
                            <Route path="/template/all-email-template" element={<AllEmailTemplate />} />
                            <Route path="/template/create-email-template" element={<CreateEmailTemplate />} />
                            <Route path="/template/demo-template" element={<DemoTemplate />} />
                            <Route path="/template/contract-template" element={<ContractTemplate />} />
                            <Route path="/template/quote-template" element={<QuoteTemplate />} />
                            <Route path="/contract/template" element={<ContractTemplate />} />
                            <Route path="/pricing/all-charge-basis" element={<AllChargebasis />} />
                            <Route path="/pricing/create-charge-basis" element={<CreateChargebasis />} />
                            <Route path="/contracts/contracts-dashboard" element={<ContractDashboard />} />
                            <Route path="/contracts/create-contract" element={<CreateContract />} />
                            <Route path="/contracts/all-contracts" element={<AllContracts />} />
                            <Route path="/pricing" element={<PricingDashboard />} />
                            <Route path="/pricing/package" element={<PackageDashboard />} />
                            <Route path="/pricing/all-packages" element={<AllPackages />} />
                            <Route path="/pricing/create-package" element={<CreatePackage />} />
                            <Route path="/pricing/view-package" element={<ViewPackage />} />
                            <Route path="/pricing/all-services" element={<AllServices />} />
                            <Route path="/pricing/create-services" element={<CreateServices />} />
                            <Route path="/pricing/edit-charge-basis" element={<EditChargebasis />} />
                            <Route path="/template/create-quote-template" element={<CreateQuoteTemplate />} />
                            
                          
                        </Routes>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AdminRoute;

