import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const AdminSidebar = () => {
  const [pricingDropdownOpen, setPricingDropdownOpen] = useState(false);
  const [templateDropdownOpen, setTemplateDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const toggleButton = document.querySelector('.toggle-sidebar-btn');

    const toggleSidebar = () => {
      document.body.classList.toggle("toggle-sidebar");
    };

    if (toggleButton) {
      toggleButton.addEventListener("click", toggleSidebar);
    }

    return () => {
      if (toggleButton) {
        toggleButton.removeEventListener("click", toggleSidebar);
      }
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes('/admin/pricing') ||
      location.pathname.includes('/admin/services') ||
      location.pathname.includes('/admin/pricing/all-charge-basis')
    ) {
      setPricingDropdownOpen(true);
    } else {
      setPricingDropdownOpen(false);
    }

    if (
      location.pathname.includes('/admin/template/all-email-template') ||
      location.pathname.includes('/admin/template/quote-template') ||
      location.pathname.includes('/admin/template/contract-template')
    ) {
      setTemplateDropdownOpen(true);
    } else {
      setTemplateDropdownOpen(false);
    }
  }, [location.pathname]);

  const redirectToPricing = () => {
    setPricingDropdownOpen(true);
    navigate('/admin/pricing');
  };

  const toggleTemplateDropdown = () => {
    setTemplateDropdownOpen(!templateDropdownOpen);
    navigate('/admin/template/all-email-template');
  };

  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <div className="toggle-sidebar-btn">
          <img src="/assets/img/toggle-img.png" alt="Toggle Sidebar" />
        </div>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink
              to="/admin/dashboard"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              end
            >
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/client/dashboard"
              className={({ isActive }) => (isActive || location.pathname.startsWith('/admin/client')) ? 'nav-link active' : 'nav-link'}

              end
            >
              <i className="bi bi-person" />
              <span>Clients</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/quote/quotes-dashboard"
              className={({ isActive }) => (isActive || location.pathname.startsWith('/admin/quote')) ? 'nav-link active' : 'nav-link'}


              end
            >
              <i className="ri-discount-percent-line" />
              <span>Quotes</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/contracts/contracts-dashboard"
              className={({ isActive }) => (isActive || location.pathname.startsWith('/admin/contracts')) ? 'nav-link active' : 'nav-link'}

            >
              <i className="ri-file-damage-line"></i>
              <span>Contracts</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link collapsed ${location.pathname.startsWith('/admin/pricing') ? 'active' : ''
                }`}
              onClick={redirectToPricing}
              data-bs-toggle="collapse"
              data-bs-target="#components-nav"
              aria-expanded={pricingDropdownOpen ? 'true' : 'false'}
            >
              <i className="bi bi-cash pricing-icon" />
              <span>Pricing</span>
              <i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul
              id="components-nav"
              className={`nav-content collapse ${pricingDropdownOpen ? 'show' : ''}`}
            >
              <li>
                <NavLink
                  to="/admin/pricing/package"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  end
                >
                  <i className="bi bi-box" />
                  <span>Package</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/pricing/all-services"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  end
                >
                  <i className="ri-shopping-bag-3-line"></i>
                  <span>Services</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/pricing/all-charge-basis"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  end
                >
                  <i className="ri-repeat-line"></i>
                  <span>Charge basis</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link collapsed ${location.pathname.startsWith('/admin/template') ? 'active' : ''
                }`}
              onClick={toggleTemplateDropdown}
              aria-expanded={templateDropdownOpen ? 'true' : 'false'}
            >
              <i className="bi bi-wallet2" />
              <span>Templates</span>
              <i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul
              id="forms-nav"
              className={`nav-content collapse ${templateDropdownOpen ? 'show' : ''}`}
            >
              <li>
                <NavLink
                  to="/admin/template/all-email-template"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  end
                >
                  <i className="bi bi-circle" />
                  <span>Email</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/template/quote-template"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  end
                >
                  <i className="bi bi-circle" />
                  <span>Quote</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/template/contract-template"
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  end
                >
                  <i className="bi bi-circle" />
                  <span>Contract</span>
                </NavLink>
              </li>
            </ul>
          </li>

        </ul>
      </aside>
    </div>
  );
};

export default AdminSidebar;
