import React, { useState, useEffect } from "react";
import ReusableModal from '../../../ExtraComponents/ReusableModal';
import { getServiceApplicable, } from '../../../ReduxStore/Slice/Pricing/ServiceSlice'
import { useDispatch } from "react-redux";
import Select from 'react-select';
import { allServiceList, } from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import { createPackage } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import {
  EMPTY_PACKAGE_NAME_ERROR, EMPTY_APPLICABLE_TO_ERROR, EMPTY_TOTAL_COST_OF_SERVICES_ERROR, EMPTY_DEFAULT_PACKAGE_PRICE_ERROR,
  EMPTY_DEFAULT_DISCOUNT_ERROR, EMPTY_THRESHOLD_PRICE_ERROR, EMPTY_MINIMUM_PRICE_ERROR, EMPTY_THRESHOLD_DISCOUNT_ERROR, EMPTY_MAXIMUM_DISCOUNT_ERROR
} from '../../../Utils/Common_Error';
import sweatalert from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreatePackage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [serviceApplicableData, setServiceApplicableData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceRowData, setServiceRowData] = useState("");
  const [PriceCalculation, setPriceCalculation] = useState([]);
  const [allServiceData, setAllServiceData] = useState([]);
  const [checkedList, setcheckedList] = useState([]);
  const handleCloseModal = () => setShowModal(false);
  const [error, setError] = useState({});

  console.log("PriceCalculation", PriceCalculation);

  const [serviceData, setServiceData] = useState({
    packageName: "",
    PackageStatus: "0",
    applicableTo: [],
    addServices: "",
    PackageDescription: "",
    DefaultPrice: "",
    ThresholdPrice: "",
    MinimunPrice: "",
    TotalCost: "",
    ThresholdDiscount: "",
    MaximumDiscount: "",
    DefaultDiscount: "",
  });

  useEffect(() => {
    ServiceApplicable();
  }, []);

  const ServiceApplicable = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getServiceApplicable(req)).unwrap();
      if (res.status) {
        setServiceApplicableData(res.data);
      }
      else {
        setServiceApplicableData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllService();
  }, [serviceData.applicableTo ]);

  const getAllService = async () => {
    const req = {
      page: 1,
      pageSize: 100,
      servicename: "",
      servicetype_id: "",
      chargebasis_id: "",
      applicableTo: serviceData.applicableTo.map((data) => data.value),
      vat: "",
      price_type: ""
    }
    await dispatch(allServiceList(req)).unwrap()
      .then((response) => {
        if (response.status) {
          const matchedArray = response.data.filter(item =>
            serviceData.applicableTo.some(data => item.ServiceApplicables.includes(data.value))
          );

          setcheckedList((prev) =>
            prev.filter((item) =>
              matchedArray.some((data) => data.id == item.id)
            )
          );

          setAllServiceData(matchedArray);
        } else {
          setServiceList([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "DefaultPrice") {
      const isValid = /^[0-9]*\.?[0-9]*$/.test(value);
      if (isValid) {
        if (Number(value) > Number(serviceData.TotalCost)) {
          setServiceData({ ...serviceData, [name]: serviceData.TotalCost });
          validate(name, serviceData.TotalCost);
        } else {
          setServiceData({ ...serviceData, [name]: value });
          validate(name, value);
        }
      }
    }

    else if (name == "MinimunPrice") {
      const isValid = /^[0-9]*\.?[0-9]*$/.test(value);
      if (isValid) {
        if (Number(value) > Number(serviceData.DefaultPrice)) {
          setServiceData({ ...serviceData, [name]: serviceData.DefaultPrice });
          validate(name, value);
        }
        else {
          setServiceData({ ...serviceData, [name]: value });
          validate(name, value);
        }
      }
    }
    else if (name == "ThresholdPrice") {
      const isValid = /^[0-9]*\.?[0-9]*$/.test(value);
      if (isValid) {
        if (Number(value) > Number(serviceData.TotalCost)) {
          setServiceData({ ...serviceData, [name]: serviceData.TotalCost });
          validate(name, value);
        }
        else {
          setServiceData({ ...serviceData, [name]: value });
          validate(name, value);
        }
      }
    }
    else {
      setServiceData({ ...serviceData, [name]: value });
      validate(name, value);
    }
  }

  const handleSubmit = () => {
    PriceCalculation.forEach((item) => {
      let totalSum = 0;
      Object.keys(item).forEach((key) => {
        if (key !== "id" && item[key].value && item[key].operator) {
          const value = parseFloat(item[key].value);
          switch (item[key].operator) {
            case "+":
              totalSum += value;
              break;
            case "-":
              totalSum -= value;
              break;
            case "×":
              totalSum *= value;
              break;
            case "÷":
              totalSum = value !== 0 ? totalSum / value : totalSum;
              break;
            default:
              console.error(`Unknown operator: ${item[key].operator}`);
          }
        }
      });
      const MatchedData = checkedList.find((item1) => item1.id == item.id);
      if (MatchedData) {
        MatchedData.price = totalSum;
      }
    });

    setcheckedList([...checkedList]);
    setShowModal(false);
  };

  const handleChangeDefultValue = (e, data, ServiceId) => {
    setPriceCalculation((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === ServiceId.id);
      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex
            ? {
              ...item,
              [data?.ChargeBasis?.name]: {
                id: e.target.value,
                operator: data?.operator,
                value:
                  data?.ServiceChargeBasisValues.find(
                    (charge) => charge.chargetype_rule_id == e.target.value
                  )?.value || "",
              },
            }
            : item
        );
      } else {

        return [

          ...prev,
          {
            id: ServiceId.id,
            [data?.ChargeBasis?.name]: {
              id: e.target.value,
              operator: data?.operator,
              value:
                data?.ServiceChargeBasisValues.find(
                  (charge) => charge.chargetype_rule_id == e.target.value
                )?.value || "",
            },
          },
        ];
      }
    });

  }

  const ShowServiceData = (data, ServiceId) => {
    const MatchedData = PriceCalculation.find((item) => item.id == ServiceId.id)
    if (MatchedData) {
      return MatchedData[data?.ChargeBasis?.name]?.id;
    }
    return "";
  }


  const validateAllFields = () => {
    let isValid = true;
    for (const key in serviceData) {
      if (!validate(key, serviceData[key])) {
        isValid = false;
      }
    }
    return isValid;
  };


  const validate = (name, value) => {
    const newErrors = { ...error };

    if (!value) {
      switch (name) {
        case "packageName":
          newErrors.packageName = EMPTY_PACKAGE_NAME_ERROR;
          break;
        case "applicableTo":
          newErrors.applicableTo = EMPTY_APPLICABLE_TO_ERROR;
          break;
        case "DefaultPrice":
          newErrors.DefaultPrice = EMPTY_DEFAULT_PACKAGE_PRICE_ERROR;
          break;
        case "ThresholdPrice":
          newErrors.ThresholdPrice = EMPTY_THRESHOLD_PRICE_ERROR;
          break;
        case "MinimunPrice":
          newErrors.MinimunPrice = EMPTY_MINIMUM_PRICE_ERROR;
          break;
        default:
          break;
      }
    }
    else if (name == "applicableTo") {
      console.log("value:", value);
      if (value.length == 0) {
        newErrors.applicableTo = EMPTY_APPLICABLE_TO_ERROR;
      }
      else {
        delete newErrors.applicableTo;
        setError((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.applicableTo;
          return updatedErrors;
        });
      }
    }
    else {
      delete newErrors[name];
      setError((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }

    if (Object.keys(newErrors).length !== 0) {
      setError((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
    }

    return Object.keys(newErrors).length === 0;
  }

  const handleSubmitPackage = async () => {

    const invalidObject = checkedList.find((obj) => obj.price === null);
    const isValid = invalidObject === undefined;

    if (!isValid) {
      sweatalert.fire({
        title: "Warning",
        text: "Please enter default value for all services",
        icon: "warning",
        confirmButtonText: "OK",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    const isValid1 = validateAllFields()
    if (!isValid1) {
      return;
    }


    const req = {
      name: serviceData.packageName,
      applicable_to: serviceData.applicableTo.map((data) => data.value),
      description: serviceData.PackageDescription,
      default_price: serviceData.DefaultPrice,
      threshold_price: serviceData.ThresholdPrice,
      min_price: serviceData.MinimunPrice,
      cost_service_price: serviceData.TotalCost,
      threshold_discount: serviceData.ThresholdDiscount,
      minimum_discount: serviceData.DefaultDiscount,
      max_discount: serviceData.MaximumDiscount,
      default_discount: serviceData.DefaultDiscount,
      packageservices: checkedList.map((service) => {
        const serviceCalculation = PriceCalculation.find((item) => item.id === service.id);
        const chargetypes = serviceCalculation
          ? Object.keys(serviceCalculation)
            .filter((key) => serviceCalculation[key]?.id !== undefined)
            .map((key) => ({
              id: serviceCalculation[key].id,
              value: serviceCalculation[key].value,
              operator: serviceCalculation[key].operator,
            }))
          : [];

        return {
          service_id: service.id,
          price: service.price,
          chargetypes,
        };
      })
    }

    await dispatch(createPackage(req)).unwrap()
      .then((response) => {
        if (response.status) {
          sweatalert.fire({
            title: "Success",
            text: response.msg,
            icon: "success",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            timer: 2000,
            timerProgressBar: true,
          }).then(() => {
            navigate("/admin/pricing/all-packages");
          })

        }
        else {
          sweatalert.fire({
            title: "Error",
            text: response.msg,
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            timer: 2000,
            timerProgressBar: true,
          })
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  }

  const SetvalueModal = (service) => {

    setShowModal(true)
    const updatedPriceCalculation = service.ServiceChargeBases.reduce((acc, data) => {
      const existingItem = PriceCalculation.find((item) => item.id == service.id);
      if (existingItem) {
        
      } else {
        acc.push({
         id: service.id,
          [data.ChargeBasis.name]: {
            id: data.ServiceChargeBasisValues[0].chargetype_rule_id,
            operator: data.operator,
            value: data.ServiceChargeBasisValues[0].value,
          },
        });
      }
      return acc;
    }, [...PriceCalculation]);
    const result = Object.values(
      updatedPriceCalculation.reduce((acc, item) => {
          const { id, ...rest } = item;
          if (!acc[id]) {
              acc[id] = { id, ...rest };
          } else {
              Object.assign(acc[id], rest);
          }
          return acc;
      }, {})
  );
  
  console.log(result);
    setPriceCalculation(result);
  }


  useEffect(() => {
    const unmatchedArray = allServiceData.filter(item1 =>
      !checkedList.some(item2 => item1.id === item2.id)
    );
    setServiceList(unmatchedArray);
  }, [checkedList])



  useEffect(() => {
    const totalCost = checkedList.reduce((acc, item) => {
      return acc + (item.price ? Number(item.price) : 0);
    }, 0);

    setServiceData({
      ...serviceData,
      TotalCost: totalCost,
      DefaultPrice: Math.min(serviceData.DefaultPrice, totalCost), 
      MinimunPrice: Math.min(serviceData.MinimunPrice, Math.min(serviceData.DefaultPrice, totalCost)), 
      ThresholdPrice: Math.min(serviceData.ThresholdPrice, totalCost),
    });
    
    setError((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.TotalCost;
      return updatedErrors;
    });

  }, [checkedList])


  useEffect(() => {
    let updatedData = { ...serviceData };
    let updatedErrors = { ...error };
  
    if (serviceData.DefaultPrice && serviceData.TotalCost) {
      let temp = serviceData.TotalCost - serviceData.DefaultPrice;
      let getPercentage = (temp / serviceData.TotalCost) * 100;
      updatedData.DefaultDiscount = getPercentage;
      delete updatedErrors.DefaultDiscount;
    }
  
    if (serviceData.DefaultPrice && serviceData.MinimunPrice) {
      let temp = serviceData.TotalCost - serviceData.MinimunPrice;
      let getPercentage = (temp / serviceData.TotalCost) * 100;
      updatedData.MaximumDiscount = getPercentage;
      delete updatedErrors.MaximumDiscount;
    }
  
    if (serviceData.ThresholdPrice && serviceData.TotalCost) {
      let temp = serviceData.TotalCost - serviceData.ThresholdPrice;
      let getPercentage = (temp / serviceData.TotalCost) * 100;
      updatedData.ThresholdDiscount = getPercentage;
      delete updatedErrors.ThresholdDiscount;
    }
  
    setServiceData(updatedData);
    setError(updatedErrors);
  }, [ serviceData.DefaultPrice, serviceData.TotalCost, serviceData.MinimunPrice,  serviceData.ThresholdPrice]);
  
  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title">New Package</h4>
        </div>
      </div>
      <div className="card form-card">
        <div className="card-header">
          <h5 className="card-title ">Create New Package</h5>
        </div>
        <div className="card-body">
          <div className=" needs-validation" >
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label "  >
                    Package Name:
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      type="text"
                      className="form-control"
                      name="packageName"
                      value={serviceData.packageName}
                      onChange={handleInputChange}
                      placeholder="Enter package name"
                    />
                    {error.packageName && <div className="text-danger">{error.packageName}</div>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label " >
                    Package status:
                  </label>
                  <div className="col-sm-8 ">
                    <select className="form-select"
                      name="PackageStatus"
                      value={serviceData.PackageStatus}
                      onChange={handleInputChange}>
                      <option value="0">Inactive</option>
                      <option value="1">Active</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Applicabel to:
                  </label>
                  <div className="col-sm-8 ">
                    <Select
                      options={serviceApplicableData?.map((data) => {
                        return {
                          label: data.name, value: data.id
                        }
                      })}
                      isMulti
                      className="basic-multi-select"
                      name="applicableTo"
                      value={serviceData.applicableTo}
                      onChange={(e) => {
                        setServiceData({ ...serviceData, applicableTo: e });
                        validate("applicableTo", e);
                      }
                      }
                      placeholder="Select options"
                    />
                    {error?.applicableTo && <div className="text-danger">{error.applicableTo}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="row">
                  <label className="col-sm-2 col-form-label "  >
                    Add Services:
                  </label>
                  <div className="col-sm-10 ">
                    <input
                      type="search"
                      className="form-control"
                      name="addServices"
                      value={serviceData.addServices}
                      onChange={handleInputChange}
                      placeholder="Search for services"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 ms-auto">
                    <div className=" ">
                      <div className="card-header">
                        <h5 className="card-title ">Services</h5>
                      </div>
                      <div className="">
                        <div className="service-table-container">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Service Name</th>
                                <th>Service Type</th>
                                <th>Values</th>
                                <th>Default Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {checkedList?.map((service) => (
                                <tr key={service?.id}>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3">
                                        <input
                                          type="checkbox"
                                          defaultChecked
                                          onClick={
                                            () => {
                                              if (checkedList?.includes(service)) {
                                                setcheckedList(checkedList?.filter((item) => item?.id != service?.id));
                                                getAllService();
                                              } else {
                                                setcheckedList([...checkedList, service]);
                                                getAllService();
                                              }
                                            }
                                          }
                                          className="form-check-input">
                                        </input>
                                      </div>
                                     <div className="text-pink"> {service?.name}</div>
                                    </div>
                                  </td>
                                  <td>{service?.price_type == "1" ? "Fixed" : "Variable"}</td>
                                  <td>{service?.price_type == "2" ? <div style={{ cursor: "pointer" }}>
                                    <a onClick={() => { SetvalueModal(service); setServiceRowData(service) }}>Enter Defult Value</a>
                                  </div> : "-"}</td>
                                  <td>{service?.price ? Number(service?.price)?.toFixed(2) : "-"}</td>
                                </tr>
                              ))}

                              {serviceList?.map((service) => (
                                service.name.toLowerCase().includes(serviceData.addServices.toLowerCase()) && (
                                  <tr key={service?.id}>
                                    <td className="border-0">
                                      <div className="d-flex">
                                        <div className="form-check me-3">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            onClick={() => {
                                              if (checkedList?.includes(service)) {
                                                setcheckedList(checkedList?.filter((item) => item?.id != service?.id));
                                              } else {
                                                setcheckedList([...checkedList, service]);
                                              }
                                            }}
                                          >
                                          </input>
                                        </div>
                                        <div className="text-pink"> {service?.name}</div>
                                      </div>
                                    </td>
                                    <td className="border-0">{service?.price_type == "1" ? "Fixed" : "Variable"}</td>
                                    <td className="border-0">{service?.price_type == "2" ? <div>
                                      <a> Enter Defult Value</a>
                                    </div> : "-"}</td>
                                    <td className="border-0">{service?.price ? service?.price : "-"}</td>
                                  </tr>
                                )
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="row">
                  <label className="col-sm-2 col-form-label "  >
                    Package description:
                  </label>
                  <div className="col-sm-10 ">
                    <textarea
                      className="form-control"
                      name="PackageDescription"
                      value={serviceData.PackageDescription}
                      onChange={handleInputChange}
                      rows="5"
                      placeholder="Enter package description"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Default price:
                  </label>
                  <div className="col-sm-8 ">
                    <div className="input-group">
                      <span className="input-group-text" id="">
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="DefaultPrice"
                        value={serviceData.DefaultPrice}
                        onChange={handleInputChange}
                        placeholder="Enter default price"
                      />

                    </div>
                    {error.DefaultPrice && <div className="text-danger">{error.DefaultPrice}</div>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Thershold price:
                  </label>
                  <div className="col-sm-8">
                    <div className="input-group">
                      <span className="input-group-text" id="">
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="ThresholdPrice"
                        value={serviceData.ThresholdPrice}
                        onChange={handleInputChange}
                        placeholder="Enter thershold price"
                      />
                    </div>
                    {error.ThresholdPrice && <div className="text-danger">{error.ThresholdPrice}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Minimun price:
                  </label>
                  <div className="col-sm-8 ">
                    <div className="input-group">
                      <span className="input-group-text" id="">
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="MinimunPrice"
                        value={serviceData.MinimunPrice}
                        onChange={handleInputChange}
                        placeholder="Enter minimun price"
                      />
                    </div>
                    {error.MinimunPrice && <div className="text-danger">{error.MinimunPrice}</div>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Total cost of services:
                  </label>
                  <div className="col-sm-8 ">
                    <div className="input-group">
                      <span className="input-group-text" id="" disabled>
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="TotalCost"
                        value={Number(serviceData?.TotalCost)?.toFixed(2)}
                        onChange={handleInputChange}
                        placeholder="Enter total cost of services"
                        disabled
                      />
                    </div>
                    {error.TotalCost && <div className="text-danger">{error.TotalCost}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Thershold discount:
                  </label>
                  <div className="col-sm-8 ">
                    <div className="input-group">
                      <span className="input-group-text" id="">
                        %
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="ThresholdDiscount"
                        disabled
                        value={Number(serviceData?.ThresholdDiscount)?.toFixed(2)}
                        onChange={handleInputChange}
                        placeholder="Enter thershold discount"
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Maximum discount:
                  </label>
                  <div className="col-sm-8 ">
                    <div className="input-group">
                      <span className="input-group-text" id="">
                        %
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="MaximumDiscount"
                        disabled
                        value={Number(serviceData?.MaximumDiscount)?.toFixed(2)}
                        onChange={handleInputChange}
                        placeholder="Enter maximum discount"
                      />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-sm-4 col-form-label ">
                    Default discount:
                  </label>
                  <div className="col-sm-8 ">
                    <div className="input-group">
                      <span className="input-group-text" id="">
                        %
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="DefaultDiscount"
                        disabled
                        value={Number(serviceData?.DefaultDiscount)?.toFixed(2)}
                        onChange={handleInputChange}
                        placeholder="Enter default discount"
                      />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-auto text-end">
              <button className="btn btn-outline-secondary " onClick={() => navigate('/admin/pricing/all-packages')}>
                Cancel
              </button>
              <button className="btn btn-pink ms-2" onClick={handleSubmitPackage}>Save</button>
            </div>
          </div>

        </div>
      </div>
      <ReusableModal
        show={showModal}
        onClose={handleCloseModal}
        title={"Enter Defult Value"}
        body={<div>
          <div className="mb-3 row">
            {serviceRowData?.ServiceChargeBases?.map((data) => (
              <div className="col-lg-12 row mb-2">
                <label className="col-lg-4 col-form-label" for="name">
                  {data?.ChargeBasis?.name}:
                </label>
                <div className="col-lg-8">
                  <select className="form-select"
                    value={ShowServiceData(data, serviceRowData)}
                    onChange={(e) => handleChangeDefultValue(e, data, serviceRowData)}
                  >
                    {data?.ServiceChargeBasisValues?.map((value) => (
                      <option value={value?.ChargeType?.id}>{value?.ChargeType?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>}
        footer={
          <>
            <button className="btn btn-outline-secondary" onClick={handleCloseModal}>
              Cancel
            </button>
            <button className="btn btn-pink" onClick={handleSubmit}>
              Apply
            </button>
          </>
        }
      />
    </div>
  );
};

export default CreatePackage;