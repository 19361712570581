import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const RichTextEditor = () => {
  const [editorData, setEditorData] = useState("");
  const [tags, setTags] = useState([]);

  // Function to handle adding a tag to the editor
  const handleTagInsert = (tag) => {
    const tagString = `{{${tag}}}`;
    setEditorData((prevData) => prevData + tagString);
    setTags((prevTags) => [...prevTags, tag]);
  };

  // Function to handle removing a tag from the editor
  const handleTagRemove = (tag) => {
    const tagString = `{{${tag}}}`;
    const updatedData = editorData.replace(tagString, ""); // Remove the tag string
    setEditorData(updatedData); // Update editor data
    setTags((prevTags) => prevTags.filter((t) => t !== tag)); // Remove tag from the tags array
  };

  return (
    <div>
      <div>
        {/* Buttons to add tags */}
        <button onClick={() => handleTagInsert('LOGO')}>Add Logo</button>
        <button onClick={() => handleTagInsert('ADDRESS')}>Add Address</button>

        {/* Display added tags with close icons */}
        <div>
          {tags.map((tag, index) => (
            <span key={index} style={{ display: 'inline-block', margin: '5px', padding: '5px', backgroundColor: '#ddd' }}>
              {tag}
              <button onClick={() => handleTagRemove(tag)} style={{ marginLeft: '5px', cursor: 'pointer' }}>✖</button>
            </span>
          ))}
        </div>
      </div>

      {/* CKEditor */}
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data); // Update editor data on change
        }}
        config={{
          toolbar: ['bold', 'italic', 'link', '|', 'undo', 'redo'],
          placeholder: 'Type here...',
        }}
      />
    </div>
  );
};

export default RichTextEditor;
