import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    Create_Package,
    view_Package,
    get_Singale_Package,
    update_Package,
    get_Singale_Service,
    Update_PackageService_Rules,
    last_Five_Packages
} from '../../../Service/Pricing/PackgeSliceService'
const UserId = JSON.parse(localStorage.getItem("details"))?.id;


export const createPackage = createAsyncThunk("createpackage", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await Create_Package(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const viewPackage = createAsyncThunk("PackageList", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await view_Package(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getSinglePackage = createAsyncThunk("GetPackageById", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Singale_Package(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const updatePackage = createAsyncThunk("updatePackage", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await update_Package(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getSingleService = createAsyncThunk("service/GetserviceById", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Singale_Service(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const UpdatePackageServiceRules = createAsyncThunk("package/UpdatePackageServiceRules", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await Update_PackageService_Rules(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const lastFivePackages = createAsyncThunk("package/lastfivepackage", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await last_Five_Packages(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});



const PackageSlice = createSlice({
    name: "PackageSlice",
    initialState: {
        isLoading: false,
        isError: false,
        createpackage: [],
        viewpackage: [],
        singlepackage: [],
        updatepackage: [],
        singalservice: []
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createPackage.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createPackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createpackage = action.payload;
            })
            .addCase(createPackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(viewPackage.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(viewPackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewpackage = action.payload;
            })
            .addCase(viewPackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getSinglePackage.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getSinglePackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.singlepackage = action.payload;
            })
            .addCase(getSinglePackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(updatePackage.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updatePackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.updatepackage = action.payload;
            })
            .addCase(updatePackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getSingleService.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getSingleService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.singalservice = action.payload;
            })
            .addCase(getSingleService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })

    },

});

export default PackageSlice;
