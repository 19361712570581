import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReusableForm from "../../../ExtraComponents/ReusableForm1";
import { getServiceType, getServiceApplicable, getPricingRule, createService } from '../../../ReduxStore/Slice/Pricing/ServiceSlice'
import { getChargeBasis } from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import { useDispatch } from "react-redux";
import { Tabs, Tab, Box } from '@mui/material';
import Select from 'react-select';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {EMPTY_SERVICE_TYPE_ERROR , EMPTY_NUMBER_OF_SERVICE_ERROR} from '../../../Utils/Common_Error'


const CreateServices = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [serviceApplicableData, setServiceApplicableData] = useState([]);
  const [getBasisData, setGetBasisData] = useState([]);
  const [pricingRuleData, setPricingRuleData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [chargeBasisRuleArr, setChargeBasisRuleArr] = useState([]);



  useEffect(() => {
    ServiceType();
    ServiceApplicable();
    GetBasisType();
  }, []);


  const ServiceType = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getServiceType(req)).unwrap();
      if (res.status) {
        setServiceTypeData(res.data);
      } else {
        setServiceTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ServiceApplicable = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getServiceApplicable(req)).unwrap();
      if (res.status) {
        setServiceApplicableData(res.data);
      }
      else {
        setServiceApplicableData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetBasisType = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getChargeBasis(req)).unwrap();
      if (res.status) {
        setGetBasisData(res.data);
      } else {
        setGetBasisData([]);
      }
    } catch (err) {
      console.log("Error in getting Basis Type", err);
    }
  };

  const setSingleValueInRuleArr = (selectedOptions) => {
    const newRuleData = {
      chargebasis_id: selectedOptions[0].tab,
      chargetype_rule_id: selectedOptions[0].id,
      chargetype_rule_value: selectedOptions[0].value,
      operator: '+'
    };
    return [newRuleData];
  }

  const RearrengArr = (arr) => {
    let newArr = [];
    arr.map((item) => {
      let obj = {
        chargebasis_id: item.chargebasis_id,
        operator: item.operator,
        chargetypes: item.chargetypes.map((data) => {
          return {
            chargetype_rule_id: data.id,
            chargetype_rule_value: data.value
          }
        })
      }
      newArr.push(obj);
    });
    return newArr;
  }




  const formik = useFormik({
    initialValues: {
      numberOfServices: "",
      serviceType: "",
      applicableTo: [],
      vatApplicable: true,
      priceType: "1",
      price: null,
      tags: "",
      ChargeBasis: [],
    },
    validate: (values) => {
      let errors = {};
      if (!values.numberOfServices) {
        errors.numberOfServices = EMPTY_NUMBER_OF_SERVICE_ERROR;
      }
      if (!values.serviceType) {
        errors.serviceType = EMPTY_SERVICE_TYPE_ERROR;
      }
      return errors;
    },

    onSubmit: async (values) => {

     
      const req = {
        token: token,
        name: values.numberOfServices,
        servicetype_id: values.serviceType,
        price_type: values.priceType,
        price: values.price,
        tag: "" + values.tags + "",
        vat: values.vatApplicable ? "1" : "0",
        applicable_to: values.applicableTo,
        servicechargebases: RearrengArr(chargeBasisRuleArr) 
      }
 
      await dispatch(createService(req)).unwrap()
        .then((res) => {
          if (res.status) {
            formik.resetForm();
            Swal.fire({
              icon: "success",
              title: res.msg,
              showConfirmButton: true,
              timer: 1500,
            });
            setTimeout(() => {
              navigate("/admin/pricing/all-services")
            }, [1500]);
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.msg,
              showConfirmButton: true,
              timer: 150000,
            });
          }
        })
        .catch((err) => {
          console.log("Error in creating Service", err);
        });
    },
  });


  useEffect(() => {
    getPricingRuleData();
  }, [formik.values.ChargeBasis]);


  useEffect(() => {
    const chargeBasisLength = formik.values.ChargeBasis.length;

    setActiveTab(formik.values.ChargeBasis[chargeBasisLength - 1]);
    const newAddedChargeBasis = pricingRuleData.filter(item => formik?.values?.ChargeBasis[formik?.values?.ChargeBasis?.length - 1] == item.id);
   

      if (chargeBasisLength === 0) {
        setChargeBasisRuleArr([]);
        setSelectedOptions([]);
      } else {
        if (chargeBasisRuleArr.length < formik.values.ChargeBasis.length) {
      
          setChargeBasisRuleArr(prevArr => [...prevArr, { chargebasis_id: formik.values.ChargeBasis[chargeBasisLength - 1], operator: '+', name: newAddedChargeBasis[0]?.name, chargetypes: newAddedChargeBasis[0]?.ChargeTypes }]);

        } else {
          setSelectedOptions(pre => pre.filter((data) => data.tab !== formik.values.ChargeBasis[chargeBasisLength - 1]));
          setSelectedOptions(prevArr => {
            const chargeBasisLength = formik.values.ChargeBasis.length;
            const newTab = formik.values.ChargeBasis[chargeBasisLength - 1];
            const newValue = newAddedChargeBasis[0]?.ChargeTypes?.[0].value;
            const newid = newAddedChargeBasis[0]?.ChargeTypes?.[0].id;
            const newName = newAddedChargeBasis[0]?.ChargeTypes?.[0].name;

            const newData = {
              tab: newTab,
              id: newid,
              label: newName,
              value: newValue
            };

            const updatedSelectedOptions = prevArr.filter(data => data.tab !== newTab).concat(newData);

            setChargeBasisRuleArr(prevRuleArr => {
              const newRuleData = {
                chargebasis_id: newTab,
                name: newAddedChargeBasis[0]?.name,
                chargetypes: newAddedChargeBasis[0]?.ChargeTypes,
                operator: '+'
              };

              const updatedRuleArr = prevRuleArr.filter(rule => rule.chargebasis_id !== newTab).concat(newRuleData);
              return updatedRuleArr;
            });
            return updatedSelectedOptions;
          });

          setChargeBasisRuleArr(prevArr => prevArr.filter(item => formik.values.ChargeBasis.includes(item.chargebasis_id)))
          setSelectedOptions(prevArr => prevArr.filter(item => formik.values.ChargeBasis.includes(item.tab))
          );
        }
      }


    }, [formik.values.ChargeBasis, pricingRuleData]);



  const getPricingRuleData = async () => {
    const req = { token: token, chargebasis_ids: formik.values.ChargeBasis };
    await dispatch(getPricingRule(req)).unwrap()
      .then((res) => {
        if (res.status) {
          setPricingRuleData(res.data);
        } else {
          setPricingRuleData([]);
        }
      })
      .catch((err) => {
        console.log("Error in getting Pricing Rule", err);
      });
  }


  const fields = [
    {
      type: "text",
      name: "numberOfServices",
      label: "Name of Service:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "select",
      name: "serviceType",
      label: "Service Type:",
      options: serviceTypeData?.map((data) => {
        return {
          label: data.name, value: data.id
        };
      }),
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "multiselect",
      name: "applicableTo",
      label: "Applicable To:",
      options: serviceApplicableData?.map((data) => {
        return {
          label: data.name, value: data.id
        }
      }),
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "checkbox",
      name: "vatApplicable",
      label: "VAT Applicable:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "select1",
      name: "priceType",
      label: "Price Type",
      options: [
        { label: "Fixed Price", value: "1" },
        { label: "Variable Price", value: "2" },
      ],
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "multiselect",
      name: "ChargeBasis",
      label: "Charge Basis",
      options: getBasisData?.map((data) => {
        return {
          label: data.name, value: data.id
        }
      }),
      showWhen: (values) => values.priceType === "2",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "text",
      name: "price",
      label: "Enter Price",
      showWhen: (values) => values.priceType === "1",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
    {
      type: "tags",
      name: "tags",
      label: "Tags",
      showWhen: (values) => values.priceType === "1",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 8,
    },
  ]

  const handleTabChange = (event, newId) => {
    setActiveTab(newId);
  };

  const options = [
    { value: '+', label: '+' },
    { value: '-', label: '-' },
    { value: '×', label: '×' },
    { value: '÷', label: '÷' }
  ];

  const handleChargeBasisRule = (selected, data, type) => {

    const index = chargeBasisRuleArr.findIndex(item => item.chargebasis_id === data.id);
    const newChargeBasisRuleArr = [...chargeBasisRuleArr];
    if (type === 'oprater') {
      newChargeBasisRuleArr[index].operator = selected.value;
    } 
    setChargeBasisRuleArr(newChargeBasisRuleArr);
  }

  const handleChange = (event, Tabs , data ) => {

    setChargeBasisRuleArr(prevArr => {
      const newChargeBasisRuleArr = prevArr.map(item => {
        if (item.chargebasis_id === Tabs) {
          const updatedTab = {
            ...item,
            chargetypes: item.chargetypes.map(chargeType => {
              if (chargeType.id === data.id) {
                return {
                  ...chargeType,
                  value: event.target.value,
                };
              }
              return chargeType; 
            }),
          };
          return updatedTab;
        }
        return item; 
      });
      return newChargeBasisRuleArr;
    });
    

    setSelectedOptions(pre => pre.filter((data) => data.tab !== Tabs));
    let data1 = { ...data, tab: Tabs };
    setSelectedOptions(pre => [...pre, data1]);
  }


  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/pricing/all-services">
              <i className="bx bx-arrow-back text-pink"></i>
            </Link>
             Add Services
          </h4>
        </div>
      </div>
      <div className="card form-card">
        <div className="card-header">
          <h5 className="card-title ">Create New Services</h5>
        </div>
        <div className="card-body">
          <ReusableForm
            fieldtype={fields.filter(
              (field) => !field.showWhen || field.showWhen(formik.values)
            )}
            formik={formik}
            closeBtn={() => navigate("/admin/pricing/all-services")}
            btn_name="Add"
            additional_field={
              <> {
                formik.values.priceType === "2" && pricingRuleData?.length > 0 && (
                  <div className="card-body" >
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} onChange={handleTabChange}>
                          {pricingRuleData?.map((data) => (
                            <Tab
                              key={data.id}
                              label={data.name}
                              value={data.id}
                              style={{ color: activeTab === data.id ? "#ff00aa" : "#000000" }}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      <Box sx={{ p: 3 }}>
                        <div className="row">
                          {pricingRuleData.length > 0 && (

                            (() => {
                              const activeTabData = pricingRuleData.find((data) => data.id === activeTab);
                              if (activeTabData && activeTabData.ChargeTypes) {
                                return activeTabData.ChargeTypes.map((chValue, index) => (
                                  <div key={chValue.id} className="form-group d-flex">
                                    <div className="col-lg-5">
                                      <label htmlFor={`input-${chValue.id}`}>{chValue.name}</label>
                                    </div>
                                    <div className="col-lg-3 mb-2">
                                      <input
                                        type="text"
                                        style={{ borderRadius: '5px', padding: '6px' }}
                                        className="w-100"
                                        placeholder="Enter Price"
                                        defaultValue={chValue.value}
                                        onChange={(e) => handleChange(e, activeTab, chValue)}
                                        disabled={activeTabData.input_required === '1' ? false : true}
                                      />
                                    </div>
                                  </div>
                                ));
                              }

                              return null;
                            })()
                          )}

                        </div>
                      </Box>
                    </Box>
                  </div>
                )}
                {
                  formik.values.priceType === "2" && pricingRuleData?.length > 1 &&
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Pricing Rule</label>
                    </div>
                    <div className="col-lg-10 ">
                      <div className="row">
                        {pricingRuleData?.map((item, index) => (
                          <>
                            {index != 0 ? <div className="col-lg-2 mb-2">
                              <Select
                                options={options}
                                isMulti={false}
                                name="oprater"
                                value={chargeBasisRuleArr?.filter((data) => data?.chargebasis_id == item?.id)?.[0]?.operator ? options?.filter((data) => data.value === chargeBasisRuleArr?.filter((data) => data?.chargebasis_id === item?.id)?.[0]?.operator) : "+"}
                                onChange={(selected) => { handleChargeBasisRule(selected, item, 'oprater') }}
                              />

                            </div> : ""}


                            <div className="col-lg-3 mb-2">
                              
                              <input type="text"
                                className="form-control border p-3"
                                style={{ borderRadius: '10px', padding: '6px' }}
                                placeholder="Enter Price"
                                value={chargeBasisRuleArr?.filter((data) => data?.chargebasis_id === item?.id)?.[0]?.name}
                                disabled={true}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                }
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateServices;
