import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Login } from '../ReduxStore/Slice/Auth/AuthSlice'
import Swal from 'sweetalert2';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    username: '',
    password: ''
  });

  const handleLogin = async () => {
    const req = {
      email: loginDetails.username,
      password: loginDetails.password
    }

    await dispatch(Login(req)).unwrap()
      .then((res) => {

        console.log("Login", res)

        
        if (res.status) { 
          localStorage.setItem('token', res.token)
          localStorage.setItem('details', JSON.stringify(res.data))
   
          
          Swal.fire({
            icon: 'success',
            title: 'Login Successful',
            showConfirmButton: false,
            timer: 2000
          })
          window.location.reload()
          setTimeout(() => {
            navigate('/admin/dashboard')
          }, 2000)

        }
        else {
          Swal.fire({
            icon: 'error',
            title: res.msg,
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/login')
        }
      })
      .catch((err) => {
        console.log("Error", err)
      })
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  return (
    <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <a
                  className="logo d-flex align-items-center w-auto"
                >
                  <img src="assets/img/logo.png" alt="" className='me-0' />
                  {/* <span className="d-none d-lg-block">Nomi</span> */}
                </a>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-2 fs-4">
                      Login to Your Account
                    </h5>
                    <p className="text-center small">
                      Enter your username &amp; password to login
                    </p>
                  </div>
                  <form className="row g-3 needs-validation" noValidate="">
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Username</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          onChange={(e) => setLoginDetails({ ...loginDetails, username: e.target.value })}
                          value={loginDetails.username}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="invalid-feedback">
                          Please enter your username.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        onChange={(e) => setLoginDetails({ ...loginDetails, password: e.target.value })}
                        value={loginDetails.password}
                        onKeyPress={handleKeyPress}
                      />
                      <div className="invalid-feedback">
                        Please enter your password!
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="remember"
                        />
                        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn btn-pink w-100" type="submit" onKeyPress={handleKeyPress} onClick={handleLogin}>Login</div>
                    </div>
                    {/* <div className="col-12">
                      <p className="small mb-0">
                        Don't have account?{" "}
                        <a href="pages-register.html">Create an account</a>
                      </p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignIn;