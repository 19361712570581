import Main_Route from './Routes/MainRoute';
function App() {
  const userDetails = JSON.parse(localStorage.getItem('details'));
  return (
    <div className="App">
      <Main_Route />
    </div>
  );
}

export default App;
