import React from "react";
import { Link } from "react-router-dom";

const CreateQuote = () => {
    return (
        <div>
            <div className="row mb-4">
                <div className="col">
                    <h4 className="page-title"><Link to="/admin/quote/quotes-dashboard">
              <i className='bx bx-arrow-back text-pink pe-1'></i>
            </Link>Quote</h4>
                </div>
            </div>
            <div className="card form-card">
                <div className="card-header">
                    <h5 className="card-title ">Create New Quote</h5>
                </div>
                <div className="card-body">
                    <form className=" needs-validation" noValidate="">
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label pe-0"
                                    >
                                        Client:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                        <input type="text" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label pe-0"
                                    >
                                        Secondary Quote type:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                    <select className="form-select">
                                    <option >Select an action</option> 
                                            <option value="1">Option 1</option> 
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label pe-0"
                                    >
                                        Business type:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                    <select className="form-select">
                                    <option >Select an action</option> 
                                            <option value="1">Option 1</option> 
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label pe-0"
                                    >
                                    Contract owner:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                        <input type="text" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label pe-0"
                                    >
                                        Enquiry type:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                    <select className="form-select">
                                    <option >Select an action</option> 
                                            <option value="1">Option 1</option> 
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label pe-0"
                                    >
                                        Adhoc services:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                    <div className="form-check">
    <input className="form-check-input" type="checkbox" id="gridCheck1" />
    
  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label pe-0">
                                       Channel:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                        <select className="form-select">
                                    <option >Select an action</option> 
                                            <option value="1">Option 1</option> 
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label pe-0">
                                        Reccuring services:
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                    <div className="form-check">
    <input className="form-check-input" type="checkbox" id="gridCheck1" />
   
  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label pe-0">
                                      Quote type
                                    </label>
                                    <div className="col-sm-7 ps-0">
                                    <select className="form-select">
                                    <option >Select an action</option> 
                                            <option value="1">Option 1</option> 
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                     
                        <div className=" col-auto text-end">
                            <button className="btn btn-outline-secondary rounded">
                                Cancel
                            </button>
                            <button className="btn btn-pink rounded ms-2">
                                Submit
                            </button>
                        </div>
                    </form>
                    {/* End Custom Styled Validation */}
                </div>
            </div>
        </div>
    );
};

export default CreateQuote;
