import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ReusableModal from '../../ExtraComponents/ReusableForm';
import SimpleDataTable from '../../ExtraComponents/SimpleDatatable'; 

import { Button } from "react-bootstrap";

const AllClients = () => {
  // State for columns and data
  const [columns] = useState([
    { name: 'Name', key: 'name' },
    { name: 'Ext.', key: 'ext' },
    { name: 'City', key: 'city' },
    { name: 'Start Date', key: 'startDate', type: 'date' },
    { name: 'Completion', key: 'completion' },
    { name: 'Action', key: 'action' }, // Added Action column
  ]);

  const [data] = useState([
    { name: 'Dale Rush', ext: '5050', city: 'Chicoutimi', startDate: '2000/27/03', completion: '2%' },
    { name: 'Shellie Murphy', ext: '3845', city: 'Marlborough', startDate: '2013/13/11', completion: '72%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },

    // Add more rows as needed
  ]);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  // Handler for the Edit button
  const handleEdit = (clientName) => {
    console.log(`Edit client: ${clientName}`);
    // Add your edit logic here
  };

  // Handler for the Delete button
  const handleDelete = (clientName) => {
    console.log(`Delete client: ${clientName}`);
    // Add your delete logic here
  };

  // Render Action column with buttons
  const dataWithActions = data.map(client => ({
    ...client,
    action: (
      <div>
        <button
          className="btn btn-sm-pink  "
          onClick={() => handleEdit(client.name)}
        >
          <i className=' ri-repeat-line pe-1'></i>
          Convert
        </button>

        <button
          className="btn fs-4 py-0 "
          onClick={() => handleEdit(client.name)}
        >
          <i className=' ri-edit-line'></i>
        </button>
        <button
          className="btn fs-4 py-0"
          onClick={() => handleDelete(client.name)}
        >
          <i className='bx bx-trash'></i>
        </button>
      </div>
    ),
  }));

  const perPage = 10; // Show 10 rows per page
  const perPageSelect = [5, 10, 15]; // Dropdown options for per-page rows
  const searchable = true; // Enable search functionality
  const paging = true; // Enable paging functionality
  // Ensure only 10 rows are displayed in the table


  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title"><Link to="/admin/client/client-dashboard" >
            <i className='bx bx-arrow-back text-pink'></i>
          </Link> All Client</h4>
        </div>
        <div className="col-auto align-self-center">
          <Link to="/admin/client/create/clients" className="btn btn-pink" id="Dash_Date">
            <span className="ay-name" id="Day_Name">
              Add New Client
            </span>
          </Link>
        </div>
      </div>

      <section className="section dashboard">
        <div className="row">
          <button className='btn w-auto filter-btn' onClick={handleShowModal}>
            <i className="bi bi-filter fs-3 text-pink"></i>
          </button>
          {/* Pass columns, modified data, and per-page configurations to SimpleDataTable */}
          <SimpleDataTable
            columns={columns}
            data={dataWithActions}
            perPage={perPage}
            perPageSelect={perPageSelect}
            searchable={searchable}
            paging={paging}
          />
        </div>
      </section>

      <ReusableModal
        show={showModal}
        onClose={handleCloseModal}
        title={<><i className="bi bi-filter" style={{ marginRight: "8px" }}></i>Filters</>}
        body=
        {<div>
          <div className='row mb-4'>
            <p className='text-semidark mb-1 font-14'>Status</p>
            <div className="col-sm-12 d-flex">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="gridCheck1" />
                <label className="form-check-label" htmlFor="gridCheck1">
                  Created
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck2"
                  defaultChecked=""
                />
                <label className="form-check-label" htmlFor="gridCheck2">
                  Reviewed
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck2"
                  defaultChecked=""
                />
                <label className="form-check-label" htmlFor="gridCheck2">
                  Accepted
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck2"
                  defaultChecked=""
                />
                <label className="form-check-label" htmlFor="gridCheck2">
                  Rejected
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck2"
                  defaultChecked=""
                />
                <label className="form-check-label" htmlFor="gridCheck2">
                  Sent
                </label>
              </div>
            </div>
          </div>
          <div className='row mb-4'>
            <p className='text-semidark mb-1 font-14'>Date</p>
            <div className="col-sm-4">
              <input className="form-control date-feild" type="date" />




            </div>
          </div>
          <div className='row'>
            <p className='text-semidark mb-1 font-14'>Date Range</p>
            <div className="col-sm-4">
              <input className="form-control date-feild" type="date" />
            </div>
            <div className="col-sm-4">
              <input className="form-control date-feild" type="date" />
            </div>
          </div>
        </div>}
        footer={
          <>
            <button className='btn btn-outline-secondary' onClick={handleCloseModal}>
              Cancel
            </button>
            <button className='btn btn-pink' onClick={() => alert("Changes Saved!")}>
              Apply
            </button>
          </>
        }
      />
    </div>
  );
};

export default AllClients;
