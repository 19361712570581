import React from "react";
import { Link } from "react-router-dom";

const CreateClient = () => {
    return (
        <div>
            <div className="row mb-4">
                <div className="col">
                    <h4 className="page-title"><Link to="/admin/client/dashboard" >
            <i className='bx bx-arrow-back text-pink pe-1'></i>
          </Link>Client</h4>
                </div>
            </div>
            <div className="card form-card">
                <div className="card-header">
                    <h5 className="card-title ">New Client</h5>
                </div>
                <div className="card-body">
                    <form className=" needs-validation" noValidate="">
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label"
                                    >
                                        First Name:
                                    </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label"
                                    >
                                        Last Name:
                                    </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label"
                                    >
                                        Email Address:
                                    </label>
                                    <div className="col-sm-8">
                                        <input type="email" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label"
                                    >
                                        Phone no:
                                    </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label"
                                    >
                                        Date of Birth:
                                    </label>
                                    <div className="col-sm-8">
                                        <input type="date" className="form-control" required="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label">
                                        Address Line 1:
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label">
                                        Address Line 2:
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label">
                                        City
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label">
                                        Country
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label">
                                        Trading Name:
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-sm-4 col-form-label">
                                        Trading Address:
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-auto text-end">
                            <button className="btn btn-outline-secondary rounded">
                                Cancel
                            </button>
                            <button className="btn btn-pink rounded ms-2">
                                Submit
                            </button>
                        </div>
                    </form>
                    {/* End Custom Styled Validation */}
                </div>
            </div>
        </div>
    );
};

export default CreateClient;
