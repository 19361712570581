import axios from "axios";
import * as Config from "../../Utils/Config";


export async function Get_Basis_Type(data) {
    try {
        const res = await axios.post(`${Config.base_url}basistypes`, data, {
            data: {},
        })
        return await res?.data;
        
    }
    catch (err) {
        return await err;
    }
}

export async function create_ChargeBasis(data) {
    try {
        const res = await axios.post(`${Config.base_url}createchargebasis`, data, {
            data: {},
        })

        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Charge_Basis(data) {
    try {
        const res = await axios.post(`${Config.base_url}getchargebasis`, data, {
            data: {},
        })

        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Charge_Basis_byId(data) {
    try {
        const res = await axios.post(`${Config.base_url}getchargebasisdetails`, data, {
            data: {},
        })

        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function delete_ChargeBasis(data) {
    try {
        const res = await axios.post(`${Config.base_url}deletechargebasis`, data, {
            data: {},
        })

        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}


export async function update_Status(data) {
    try {
        const res = await axios.post(`${Config.base_url}statusupdate`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function update_Change_Basis(data) {
    try {
        const res = await axios.post(`${Config.base_url}updatechargebasis`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function All_Services_List(data) {
    try {
        const res = await axios.post(`${Config.base_url}servicelist`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}


export async function All_PackageServices(data) {
    try {
        const res = await axios.post(`${Config.base_url}package/editPackageGetService`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}







