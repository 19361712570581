import axios from "axios";
import * as Config from "../../Utils/Config";


export async function get_Service_Type(data) {
    try {
        const res = await axios.post(`${Config.base_url}getServiceType`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Service_ApplicableTo(data) {
    try {
        const res = await axios.post(`${Config.base_url}getServiceApplicable`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}


export async function get_Pricing_Rule(data) {
    try {
        const res = await axios.post(`${Config.base_url}chargebasisdata`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function create_Service(data) {
    try {
        const res = await axios.post(`${Config.base_url}createService`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function UpdateService(data) {
    try {
        const res = await axios.post(`${Config.base_url}updateService`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}






