import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import SimpleDataTable from '../../ExtraComponents/SimpleDatatable';  // Import the child component

const ContractDashboard = () => {

    // State for columns and data
    const [columns] = useState([
        { name: 'Pricing no', key: 'price' },
        { name: 'Subject', key: 'subject' },
        { name: 'Pricing Status', key: 'status' },
        { name: 'Client/lead', key: 'lead' },
        { name: 'Recuring amount yearly', key: 'amount' },
        { name: 'Adhoc Amount', key: 'adhoc' },
        { name: 'Date', key: 'date', type: 'date' }, // Added Action column
    ]);

    const [data] = useState([
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        { price: 'QC15350', subject: 'contract Gupta_15350', status: 'contract signature', lead: 'Berkshire Venture', amount: '1200.00', adhoc: '0.00', date: '17-09-2024 12:01 PM' },
        // Add more rows as needed
    ]);
    const limitedData = data.slice(0, 5);

    // Handler for the Edit button
    const handleEdit = (clientName) => {
        console.log(`Edit client: ${clientName}`);
        // Add your edit logic here
    };

    // Handler for the Delete button
    const handleDelete = (clientName) => {
        console.log(`Delete client: ${clientName}`);
        // Add your delete logic here
    };

    return (
        <div>
            <>
                <div className="row mb-3">
                    <div className="col">
                        <h4 className="page-title">Contracts</h4>
                    </div>
                    <div className="col-auto align-self-center">
                        <Link to="/admin/contracts/create-contract" className="btn btn-pink" id="Dash_Date">
                            <span className="ay-name" id="Day_Name">
                                Add New Contracts
                            </span>
                        </Link>
                    </div>
                </div>

                <div className="filter-select mb-3">
                    <select
                        className="form-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                    >
                        <option selected="">Current Month</option>
                        <option value={1}>Last Month</option>
                        <option value={2}>Two</option>
                        <option value={3}>Three</option>
                    </select>
                </div>

                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-xxl-3 col-md-3">
                                    <div className="card info-card sales-card">
                                        <div className="card-body">
                                            <h5 className="card-title pb-4">Contracts sent</h5>
                                            <div className="d-flex align-items-end justify-content-between ">
                                                <div className="">
                                                    <h6 style={{ fontSize: "20px" }}>83</h6>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-3 col-md-3">
                                    <div className="card info-card sales-card">
                                        <div className="card-body">
                                            <h5 className="card-title pb-4">Contracts signed</h5>
                                            <div className="d-flex align-items-end justify-content-between ">
                                                <div className="">
                                                    <h6 style={{ fontSize: "20px" }}>57</h6>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-md-3">
                                    <div className="card info-card sales-card">
                                        <div className="card-body">
                                            <h5 className="card-title pb-4">Awaiting signature</h5>
                                            <div className="d-flex align-items-end justify-content-between ">
                                                <div className="">
                                                    <h6 style={{ fontSize: "20px" }}>24</h6>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-md-3">
                                    <div className="card info-card sales-card">
                                        <div className="card-body">
                                            <h5 className="card-title pb-4">Unsent contracts</h5>
                                            <div className="d-flex align-items-end justify-content-between ">
                                                <div className="">
                                                    <h6 style={{ fontSize: "20px" }}>19</h6>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col">
                            <span className="text-pink font-16">Recent</span>
                        </div>
                        <div className="col-auto align-self-center">
                            <Link to="/admin/contracts/all-contracts" >
                                <span className="text-pink font-16">See All</span>
                            </Link>
                        </div>

                        <SimpleDataTable columns={columns} data={limitedData} perPage={5} perPageSelect={false} />

                    </div>
                </section>
            </>
        </div>
    );
}

export default ContractDashboard;
