import React, { useState } from 'react';
import { Link } from "react-router-dom";
import SimpleDataTable from '../../../ExtraComponents/SimpleDatatable';  // Import the child component

const QuoteTemplate = () => {
  // State for columns and data
  const [columns] = useState([
    { name: 'Name', key: 'name' },
    { name: 'Ext.', key: 'ext' },
    { name: 'City', key: 'city' },
    { name: 'Start Date', key: 'startDate', type: 'date' },
    { name: 'Completion', key: 'completion' },
    { name: 'Action', key: 'action' }, // Added Action column
  ]);

  const [data] = useState([
    { name: 'Dale Rush', ext: '5050', city: 'Chicoutimi', startDate: '2000/27/03', completion: '2%' },
    { name: 'Shellie Murphy', ext: '3845', city: 'Marlborough', startDate: '2013/13/11', completion: '72%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },
    { name: 'Porter Nicholson', ext: '4539', city: 'Bismil', startDate: '2012/22/10', completion: '23%' },

    // Add more rows as needed
  ]);

  // Handler for the Edit button
  const handleEdit = (clientName) => {
    console.log(`Edit client: ${clientName}`);
    // Add your edit logic here
  };

  // Handler for the Delete button
  const handleDelete = (clientName) => {
    console.log(`Delete client: ${clientName}`);
    // Add your delete logic here
  };

  // Render Action column with buttons
  const dataWithActions = data.map(client => ({
    ...client,
    action: (
      <div>
        <button
          className="btn btn-sm-pink"
          onClick={() => handleEdit(client.name)}
        >
          <i className=' ri-repeat-line pe-1'></i>
          Convert
        </button>

        <button
          className="btn fs-4 py-0"
          onClick={() => handleEdit(client.name)}
        >
          <i className=' ri-edit-line'></i>
        </button>
        <button
          className="btn fs-4 py-0"
          onClick={() => handleDelete(client.name)}
        >
          <i className='bx bx-trash'></i>
        </button>
      </div>
    ),
  }));

  // Define the per-page and per-page-select options specific for this table
  const perPage = 10; // Show 10 rows per page
  const perPageSelect = [5, 10, 15]; // Dropdown options for per-page rows
  const searchable = true; // Enable search functionality
  const paging = true; // Enable paging functionality

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">
            Quotes Template
          </h4>
        </div>
        <div className="col-auto align-self-center">
          <Link to="/admin/template/create-quote-template" className="btn btn-pink" id="Dash_Date">
            <span className="ay-name" id="Day_Name">Add New Template</span>
          </Link>
        </div>
      </div>

      <section className="section dashboard">
        <div className="row">
          {/* Pass columns, modified data, and per-page configurations to SimpleDataTable */}
          <SimpleDataTable
            columns={columns}
            data={dataWithActions}
            perPage={perPage}
            perPageSelect={perPageSelect}
            searchable={searchable}
            paging={paging}
          />
        </div>
      </section>
    </div>
  );
};

export default QuoteTemplate;
