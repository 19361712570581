import React, { useState } from 'react';
import { Link } from "react-router-dom";
import SimpleDataTable from '../../ExtraComponents/SimpleDatatable';  // Import the child component
import Datatable from '../../ExtraComponents/ReusableTable1';
import ReusableModal from '../../ExtraComponents/ReusableModal';

const AllQuotes = () => {

  const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

  // State for columns and data
  const columns = [
    {
      name: "Subject",
      selector: (row) => row.subject,
      width:'20%'
    },
    {
      name: "Client",
      selector: (row) => row.client,
    },
    {
      name: "Yearly Recurring Amount",
      selector: (row) => row.yearlyRecurringAmount,
    },
    {
      name: "Adhoc Amount",
      selector: (row) => row.adhocAmount,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Pricing ID/No",
      selector: (row) => row.pricingId,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      width:'20%',
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm-pink"
            onClick={() => handleEdit(row.client)}
          >
            <i className="ri-repeat-line pe-1"></i>
            Convert
          </button>
  
          <button
            className="btn fs-4 py-0"
            onClick={() => handleEdit(row.client)}
          >
            <i className="ri-edit-line"></i>
          </button>
          <button
            className="btn fs-4 py-0"
            onClick={() => handleDelete(row.client)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  
  const data = [
    {
      id: 1,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 1",
      yearlyRecurringAmount: "$1200",
      adhocAmount: "$200",
      date: "2024/01/01",
      pricingId: "PRIC-001",
      status: "Active",
    },
    {
      id: 2,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 2",
      yearlyRecurringAmount: "$1500",
      adhocAmount: "$300",
      date: "2023/12/15",
      pricingId: "PRIC-002",
      status: "Inactive",
    },
    {
      id: 3,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 3",
      yearlyRecurringAmount: "$2000",
      adhocAmount: "$500",
      date: "2024/03/10",
      pricingId: "PRIC-003",
      status: "Active",
    },
    {
      id: 4,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 4",
      yearlyRecurringAmount: "$2500",
      adhocAmount: "$100",
      date: "2024/05/20",
      pricingId: "PRIC-004",
      status: "Inactive",
    },
    {
      id: 5,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 5",
      yearlyRecurringAmount: "$1800",
      adhocAmount: "$400",
      date: "2024/02/28",
      pricingId: "PRIC-005",
      status: "Active",
    },
    {
      id: 6,
      subject: "Lorem ipsum dolor sit amet, consectetur adipiscing ",
      client: "Client 6",
      yearlyRecurringAmount: "$2200",
      adhocAmount: "$150",
      date: "2024/06/01",
      pricingId: "PRIC-006",
      status: "Inactive",
    },
  ];
  

  // Handler for the Edit button
  const handleEdit = (clientName) => {
    console.log(`Edit client: ${clientName}`);
    // Add your edit logic here
  };

  // Handler for the Delete button
  const handleDelete = (clientName) => {
    console.log(`Delete client: ${clientName}`);
    // Add your delete logic here
  };

  // Render Action column with buttons
  const dataWithActions = data.map(client => ({
    ...client,
    action: (
      <div>
        <button
          className="btn btn-sm-pink"
          onClick={() => handleEdit(client.name)}
        >
          <i className=' ri-repeat-line pe-1'></i>
          Convert
        </button>

        <button
          className="btn fs-4 py-0"
          onClick={() => handleEdit(client.name)}
        >
          <i className=' ri-edit-line'></i>
        </button>
        <button
          className="btn fs-4 py-0"
          onClick={() => handleDelete(client.name)}
        >
          <i className='bx bx-trash'></i>
        </button>
      </div>
    ),
  }));

  // Define the per-page and per-page-select options specific for this table
  const perPage = 10; // Show 10 rows per page
  const perPageSelect = [5, 10, 15]; // Dropdown options for per-page rows
  const searchable = true; // Enable search functionality
  const paging = true; // Enable paging functionality

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/quote/quotes-dashboard">
              <i className='bx bx-arrow-back text-pink'></i>
            </Link> All Quotes
          </h4>
        </div>
        <div className="col-auto align-self-center">
          <Link to="/admin/quote/create-quote" className="btn btn-pink" id="Dash_Date">
            <span className="ay-name" id="Day_Name">Add New Quote</span>
          </Link>
        </div>
      </div>

      <section className="section dashboard">
      <div className="row">
          <data className='col-lg-3 datatable-search'>
            <input type="text"
              className='search p-2 icon'
              placeholder='Search here'
             
            />
          </data>
          <div className='col-lg-5'>
            <button className='btn w-auto filter-btn' onClick={handleShowModal}>
              <i className="bi bi-filter fs-3 text-pink"></i>
            </button>
          </div>
        </div>
        <div className="row">
          {/* Pass columns, modified data, and per-page configurations to SimpleDataTable */}
         
            <Datatable
              columns={columns}
              data={data}
              filter={false}
             
            />
           
        </div>


   

      </section>

      <ReusableModal
  show={showModal} // Use the state variable here
  onClose={handleCloseModal} // This works fine as is
  title={
    <>
      <i className="bi bi-filter" style={{ marginRight: "8px" }}></i>
      Filters
    </>
  }
  body={
    <div>
      <div className="row mb-4">
        <p className="text-semidark mb-1 font-14">Status</p>
        <div className="col-sm-12 d-flex">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck1"
            />
            <label className="form-check-label" htmlFor="gridCheck1">
              Created
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck2"
              defaultChecked=""
            />
            <label className="form-check-label" htmlFor="gridCheck2">
              Reviewed
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck3"
              defaultChecked=""
            />
            <label className="form-check-label" htmlFor="gridCheck3">
              Accepted
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck4"
              defaultChecked=""
            />
            <label className="form-check-label" htmlFor="gridCheck4">
              Rejected
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck5"
              defaultChecked=""
            />
            <label className="form-check-label" htmlFor="gridCheck5">
              Sent
            </label>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <p className="text-semidark mb-1 font-14">Date</p>
        <div className="col-sm-4">
          <input className="form-control date-feild" type="date" />
        </div>
      </div>
      <div className="row">
        <p className="text-semidark mb-1 font-14">Date Range</p>
        <div className="col-sm-4">
          <input className="form-control date-feild" type="date" />
        </div>
        <div className="col-sm-4">
          <input className="form-control date-feild" type="date" />
        </div>
      </div>
    </div>
  }
  footer={
    <>
      <button
        className="btn btn-outline-secondary"
        onClick={handleCloseModal}
      >
        Cancel
      </button>
      <button
        className="btn btn-pink"
        onClick={() => alert("Changes Saved!")}
      >
        Apply
      </button>
    </>
  }
/>
    </div>
  );
};

export default AllQuotes;
