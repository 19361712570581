import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReusableModal from '../../../ExtraComponents/ReusableModal';
import { getChargeBasis, deleteChargeBasis, updateStatus } from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable1';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AllChargebasis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chargeBasisData, setChargeBasisData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    getChargeBasisData();
  }, []);

  const getChargeBasisData = async () => {
    await dispatch(getChargeBasis()).unwrap()
      .then((response) => {
        if (response.status) {
          setChargeBasisData(response.data);
        } else {
          setChargeBasisData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const handleEdit = (row) => {
    navigate("/admin/pricing/edit-charge-basis", { state: { data: row } });
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff00aa",
      cancelButtonColor: "#616161",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteChargeBasis({ id: row.id }))
          .then((response) => {
            if (response.payload.status) {
              Swal.fire({
                title: "Deleted!",
                text: response.payload.msg,
                icon: "success",
                timer: 2000,
                timerProgressBar: true,
              });
              getChargeBasisData();
            }
            else{
              Swal.fire({
                title: "Error!",
                text: response.payload.msg,
                icon: "error",
                timer: 30000,
                timerProgressBar: true,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting charge basis:", error);
          });
      }
    });
  };

  const handleChangeStatus = (row, e, status) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff00aa",
      cancelButtonColor: "#616161",
      confirmButtonText: "Yes, change it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateStatus({ id: row.id, status: status ? "1" : "0" }))
          .unwrap()
          .then((response) => {
            if (response.status) {
              getChargeBasisData();
              e.target.checked = status;
              Swal.fire({
                title: "Changed!",
                text: "Your status has been changed.",
                icon: "success",
                timer: 2000,
                timerProgressBar: true,
              });
            }
          })
          .catch((error) => {
            console.error("Error updating charge basis:", error);
          });
      } else {
        e.target.checked = !status;
        getChargeBasisData();
      }
    });
  };

  const columns = [
    {
      name: "Charge Basis Name",
      selector: (row) => row.name,
      sortable: true,
      width: "33%",
    },
    {
      name: "Type",
      selector: (row) => row.BasisType.name || "-",
      sortable: true,
      width: "33%",
    },
    {
      name: "Actions",
      cell: (row) => (
        
        <div className='d-flex align-items-center'>
          <div className="form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              defaultChecked={row.status === "1"}
              onClick={(e) => handleChangeStatus(row, e, e.target.checked)}
            />
          </div>
          <button className="btn fs-4 py-0">
            <i className='ri-edit-line' onClick={() => handleEdit(row)}></i>
          </button>
          {
          row.deleted ? (
          <button className="btn fs-4 py-0" onClick={() => handleDelete(row)}>
            <i className='bx bx-trash'></i>
          </button>
           ) :''}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "33%",
    },
  ];

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">Charge Basis</h4>
        </div>
        <div className="col-auto align-self-center">
          <Link to="/admin/pricing/create-charge-basis" className="btn btn-pink" id="Dash_Date">
            <span className="ay-name" id="Day_Name">Create New</span>
          </Link>
        </div>
      </div>

      <section className="section dashboard">
        <div className="row">
        
          <Datatable
            columns={columns}
            data={chargeBasisData}
            filter={true}
          />
        </div>
      </section>
    </div>
  );
};

export default AllChargebasis;
